// import React, { useEffect, useState } from "react";
// import { AiOutlineRollback } from "react-icons/ai";
// import { BsArrowBarRight } from "react-icons/bs";
// import { BsArrowBarDown } from "react-icons/bs";
// import { Link } from "react-router-dom";
// import Data from "./Data";
// import firebase from "../../firebaseconfig";
// // import firebase from 'firebase/compat/app';
// import data2 from "./data2";
// import { FaBitcoin } from "react-icons/fa";

// const Finance = () => {
//   const [departmentData, setDepartmentData] = useState({});
//   const [records, setRecords] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [searchResults, setSearchResults] = useState([]);
//   const [balance, setBalance] =  useState()

//   const handleSearch = () => {
//     const filteredRecords = records.filter((record) => {
//       return (
//         record.price.includes(searchTerm) ||
//         record.date.includes(searchTerm) ||
//         record.from.includes(searchTerm) ||
//         record.type.includes(searchTerm)
//       );
//     });
//     setSearchResults(filteredRecords);
//   };

//   const handleClearSearch = () => {
//     setSearchTerm("");
//     setSearchResults([]);
//   };

//   useEffect(() => {
//     const db = firebase.firestore();
//     const transactionRef = db.collection("Transactions").orderBy('createdAt', 'desc')
//     transactionRef
//       .get()
//       .then((querySnapshot) => {
//         const data = [];
//         querySnapshot.forEach((doc) => {
//           const transactionData = doc.data();
//           data.push(transactionData);
//           console.log("Fetched data: ", transactionData);
//         });
//         setRecords(data);
//       })
//       .catch((error) => {
//         console.error("Error fetching data: ", error);
//       });
//   }, []);

//   useEffect(() => {
//     const db = firebase.firestore();
//     const transactionRef = db.collection("BCS");

//     transactionRef
//       .get()
//       .then((querySnapshot) => {
//         const data = [];
//         let totalBalance = 0; // Initialize total balance

//         querySnapshot.forEach((doc) => {
//           const transactionData = doc.data();
//           data.push(transactionData);

//           // Check if "balance" exists and add it to the total
//           if (transactionData.balance) {
//             totalBalance += transactionData.balance;
//           }

//           // console.log("Fetched data: ", transactionData.balance);
//         });

//       setBalance( totalBalance); // Log the total balance

//       })
//       .catch((error) => {
//         console.error("Error fetching data: ", error);
//       });
//   }, []);

//   const formatTimestamp = (timestamp) => {
//     if (timestamp) {
//       const date = timestamp.toDate(); // Convert to JavaScript Date
//       return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
//     } else {
//       return "Invalid Timestamp";
//     }
//   };

//   return (
//     <div className="ml-40">
//        <h1 className="text-gray-500 font-bold text-lg text-center m-5">
//         GENERAL
//       </h1>
//       <div className="lg:flex grid justify-center">
//         <div className="shadow-lg p-5 h-44 w-96 shadow-blue-200 bg-[#3ec9d6] rounded-md text-center">
//           <FaBitcoin size={50} color="#3ec9d6" className="self-center" />
//           <h2 className="font-extrabold text-5xl -mt-5 text-white m-4">
//           Balance
//           </h2>
//           <h2 className="font-extrabold text-2xl text-white m-4">
//             {/* {balance} */}
//             ₦{balance ? balance.toLocaleString("en-US") : '0'}
//           </h2>
//         </div>

// {
//   firebase?.auth()?.currentUser?.email === 'bcsict@ooobcs.org' &&
//         <Link to={'/Add'} className="shadow-lg p-5 h-44 w-96 cursor-pointer ml-10 shadow-blue-200 bg-[#3ec9d6] rounded-md text-center">
//           <FaBitcoin size={50} color="#3ec9d6" className="self-center" />
//           <h2 className="font-extrabold text-5xl -mt-5 text-white m-4">
//           Add
//           </h2>
//         </Link>
// }
//       </div>

//       <div className="mt-20">
//         <table className="w-full text-sm text-left dark:text-gray-400">
//           <thead className="uppercase bg-gray-50 dark:bg-gray-700">
//             <tr>
//               <th scope="col" className="px-6 py-3">
//                 Type
//               </th>
//               <th scope="col" className="px-6 py-3">
//                 AMOUNT
//               </th>
//               <th scope="col" className="px-6 py-3">
//                 PURPOSE
//               </th>
//               <th scope="col" className="px-6 py-3">
//                 DEPARTMENT
//               </th>
//               <th scope="col" className="px-6 py-3">
//                 PAID BY
//               </th>

//               <th scope="col" className="px-6 py-3">
//                 TRACKING ID
//               </th>
//               <th scope="col" className="px-6 py-3">
//                 DATE
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             {searchResults.length > 0
//               ? searchResults.map((record, index) => (
//                   <tr key={index}>
//                     <td
//                       className={`px-4 py-2 font-bold text-lg ${
//                         record.type === "Withdrawal"
//                           ? "text-red-500"
//                           : "text-green-500"
//                       }`}
//                     >
//                       {record.type}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                       {record.from}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                     ₦{record.PayeeAmount ? record.PayeeAmount.toLocaleString("en-US") : '0'}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                       {record.date}
//                     </td>
//                   </tr>
//                 ))
//               : records.map((record, index) => (
//                   <tr key={index}>
//                     <td
//                       className={`px-4 py-2 font-bold text-lg ${
//                         record.type === "Withdrawal"
//                           ? "text-red-500"
//                           : "text-green-500"
//                       }`}
//                     >
//                       {record.type}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                       ₦{record.PayeeAmount ? record.PayeeAmount.toLocaleString("en-US") : '0'}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                       {record.PaymentDescription}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">

//                       {record.PayeePurpose}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                       {record.payeeName}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                       {record.transactionId}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                     {record.createdAt ? formatTimestamp(record.createdAt) : 'No date available'}
//                     </td>
//                   </tr>
//                 ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Finance;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Space, Input, DatePicker, Button } from "antd";
import firebase from "../../firebaseconfig";
import { FaBitcoin } from "react-icons/fa";
import { auth } from "../../firebase";

const Mandate = () => {
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [balance, setBalance] = useState();
  const [loading, setLoading] = useState(false);
  const { Search } = Input;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [Mandate, setMandate] = useState(0);

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => (
        <span
          className={`font-bold text-lg ${
             "text-red-500"
          }`}
        >
          Withdrawal
        </span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <span className="font-bold text-lg">
          ₦
          {record.amount
            ? record.amount.toLocaleString("en-US")
            : "0"}
        </span>
      ),
    },
    {
      title: "Done By",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <span className="font-bold text-lg">{record.email}</span>
      ),
    },
    // {
    //   title: "Department",
    //   dataIndex: "PayeePurpose",
    //   key: "PayeePurpose",
    //   render: (text, record) => (
    //     <Link to={`/department/${record.bcsid}`}><span className="font-bold text-lg">{record.PayeePurpose}</span></Link>
    //   ),
    // },
    // {
    //   title: "Paid By",
    //   dataIndex: "payeeName",
    //   key: "payeeName",
    //   render: (text, record) => (
    //     <span className="font-bold text-lg">{record.payeeName}</span>
    //   ),
    // },
    // {
    //   title: "Tracking ID",
    //   dataIndex: "transactionId",
    //   key: "transactionId",
    //   render: (text, record) => (
    //     <span className="font-bold text-lg">{record.transactionId}</span>
    //   ),
    // },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <span className="font-bold text-lg">
          {record.createdAt
            ? formatTimestamp(record.createdAt)
            : "No date available"}
        </span>
      ),
    },
  ];

  // const fetchRecords = async () => {
  //   try {
  //     const snapshot = await firebase.firestore().collection("Transactions").orderBy("createdAt", "desc").get();
  //     const data = snapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     setRecords(data);
  //   } catch (error) {
  //     console.error("Error fetching data: ", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchRecords();
  // }, []);

  useEffect(() => {
    const db = firebase.firestore();
    setLoading(true);
    const transactionRef = db
      .collection("Mandate")
      .orderBy("createdAt", "desc");
    transactionRef
      .get()
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          const transactionData = doc.data();
          data.push(transactionData);
        });
        setRecords(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  useEffect(() => {
    const db = firebase.firestore();
    const transactionRef = db.collection("Mandate");

    transactionRef
      .get()
      .then((querySnapshot) => {
        const data = [];
        let totalBalance = 0; // Initialize total balance

        querySnapshot.forEach((doc) => {
          const transactionData = doc.data();
          data.push(transactionData);

          // Check if "balance" exists and add it to the total
          if (transactionData.amount) {
            totalBalance += transactionData.amount;
          }

          // console.log("Fetched data: ", transactionData.balance);
        });

        setBalance(totalBalance); // Log the total balance
        // setBalance(totalBalance - 60000000); // Log the total balance
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  const formatTimestamp = (timestamp) => {
    if (timestamp) {
      const date = timestamp.toDate(); // Convert to JavaScript Date
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    } else {
      return "Invalid Timestamp";
    }
  };

  const handleSearch = (value) => {
    const lowerCaseSearchTerm = value.toLowerCase();
    const filteredRecords = records.filter((record) => {
      return (
        (record.PayeeAmount &&
          record.PayeeAmount.toString().includes(lowerCaseSearchTerm)) ||
        (record.transactionId &&
          record.transactionId.includes(lowerCaseSearchTerm)) ||
        (record.payeeName &&
          record.payeeName.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (record.PayeePurpose &&
          record.PayeePurpose.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (record.PaymentDescription &&
          record.PaymentDescription.toLowerCase().includes(
            lowerCaseSearchTerm
          )) ||
        (record.type && record.type.toLowerCase().includes(lowerCaseSearchTerm))
      );
    });
    setSearchResults(filteredRecords);
  };
  const filterRecordsByDateRange = () => {
    if (startDate && endDate) {
      const filteredRecords = records.filter((record) => {
        const recordDate = record.createdAt.toDate(); // Update this line to access the correct field name
        return recordDate >= startDate && recordDate <= endDate;
      });
      setSearchResults(filteredRecords);
    } else {
      // If no date range is selected, reset searchResults to show all records
      setSearchResults(records);
    }
  };
  
  
  
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    }
  };

  const data = {
    amount: parseFloat(Mandate),
    email: auth?.currentUser?.email,
    createdAt: new Date()
  };
  const firestore = firebase.firestore();

  const HandleMandate = async () => {
    try {
      await firestore.collection("Mandate").add(data);
      setMandate(0)
      // console.log("Payment data saved successfully");
      alert('Mandate Successful')
    } catch (error) {
      console.error("Error saving payment data: ", error);
    }
  }
  return (
    <div className="md:ml-20">
      {/* <h1 className="text-gray-500 font-bold text-lg text-center m-5">GENERAL</h1> */}
      <div className="lg:flex grid justify-center">
        <div className="shadow-lg p-5 h-44 lg:w-[80%] w-96 shadow-blue-200 bg-[#3ec9d6] rounded-md text-center mb-7">
          <FaBitcoin size={50} color="#3ec9d6" className="self-center" />
          <h2 className="font-extrabold text-5xl -mt-5 text-white m-4">
            Balance
          </h2>
          <h2 className="font-extrabold text-2xl text-white m-4">
          -  ₦{balance ? balance?.toLocaleString("en-US") : "0"}
          </h2>
        </div>

      </div>
      {/* <div className="flex row">
        <Search
          placeholder="Search by Name, Amount, Date, or Purpose"
          onSearch={handleSearch}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          style={{ width: "100%", maxWidth: "400px", marginBottom: "1rem" }}
          className="bg-blue-400 rounded-xl"
          enterButton
        />

        <div className="flex row ml-16">
          <DatePicker.RangePicker
            onChange={handleDateRangeChange}
            className="ml-3 h-8"
            // style={{ marginRight: "10px" }}
          />
            <Button type="primary" onClick={filterRecordsByDateRange} className="bg-blue-600 ml-7">
              Filter
            </Button>
        </div>
      </div> */}

      <div className="mt-20 relative overflow-x-auto shadow-md sm:rounded-lg">
        <Table
          columns={columns}
          loading={loading}
          dataSource={
            searchResults.length > 0 ? searchResults : records // Use searchResults if available, otherwise fallback to records
          }
          pagination={{
            pageSize: 10,
          }}
          className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        />
      </div>
    </div>
  );
};

export default Mandate;
