import React, { useEffect, useState } from 'react';
import firebase from '../../firebaseconfig';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { BiMoneyWithdraw } from 'react-icons/bi';
import { FaBitcoin } from 'react-icons/fa';


const Audio = () => {
  const [directTitheTransactions, setDirectTitheTransactions] = useState([]);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [withdrawalName, setWithdrawalName] = useState("");
  const [withdrawalReason, setWithdrawalReason] = useState("");
  const [directTithes, setDirectTithes] = useState([]);


useEffect(() => {
  const fetchData = async () => {
    const firestore = firebase.firestore();
    const usersRef = firestore.collection("Users");
    const paymentsRef = usersRef.doc("Payments").collection("Transactions"); // Reference to the 'Transactions' subcollection

    const querySnapshot = await paymentsRef
      .where("PayeePurpose", "==", "audio_unit")
      .get();

    const tithes = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setDirectTithes(tithes);
    // console.log("Fetched direct tithes:", tithes);
  };

  fetchData();
}, []);


  const handleWithdraw = async () => {
    const withdrawal = {
      amount: parseFloat(withdrawalAmount),
      name: withdrawalName,
      reason:withdrawalReason,
      purpose: "audio_unit", // Set the purpose as "Direct Tithe"
      createdAt: new Date(),
    };
    const newBalance = directTitheBalance - withdrawal.amount;
    await firebase.firestore().collection("PurposeBalances").doc("audio_unit").set({ balance: newBalance }, { merge: true });

    // Save the withdrawal in the "DirectTitheWithdrawals" collection
    await firebase.firestore().collection("audio_unitWithdrawals").add(withdrawal);

    // Update the displayed transaction list
    setDirectTitheBalance(newBalance);
    setTotalWithdrawnAmount(totalWithdrawnAmount + withdrawal.amount);

    // Clear the input fields
    setWithdrawalAmount(0);
    setWithdrawalName("");
    setWithdrawalReason("");
  };

  const [directTitheWithdrawals, setDirectTitheWithdrawals] = useState([]);
  const [directTitheBalance, setDirectTitheBalance] = useState(0);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('audio_unitWithdrawals')
      .onSnapshot((querySnapshot) => {
        const withdrawalsData = [];
        querySnapshot.forEach((doc) => {
          withdrawalsData.push({ id: doc.id, ...doc.data() });
        });
        setDirectTitheWithdrawals(withdrawalsData);
      });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchDirectTitheBalance = async () => {
      const balanceDoc = await firebase
        .firestore()
        .collection("PurposeBalances")
        .doc("audio_unit")
        .get();

      if (balanceDoc.exists) {
        const balance = balanceDoc.data().balance;
        setDirectTitheBalance(balance);
      }
    };

    fetchDirectTitheBalance();
  }, []);

  const [totalWithdrawnAmount, setTotalWithdrawnAmount] = useState(0);

  useEffect(() => {
    const fetchTotalWithdrawnAmount = async () => {
      const querySnapshot = await firebase.firestore().collection("audio_unitWithdrawals").get();
      let total = 0;

      querySnapshot.forEach((doc) => {
        total += doc.data().amount;
      });

      setTotalWithdrawnAmount(total);
    };

    fetchTotalWithdrawnAmount();
  }, []);
  const currencyFormatter = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 2,
  });

  return (
    <div>
      <div> 
        <div className='flex bg-white shadow-lg border-b-2 border-blue-100 h-44 mb-20 justify-center items-center p-5'>
          <Link to={ "/finance"}> <BsArrowLeftCircle className='mr-10 hover:text-gray-900' size={35} color='gray'/> </Link>
            <div className='mr-10 gap-2 border-2 border-blue-600 p-2 justify-center items-center rounded-md flex'>
         <h2 className='font-extrabold text-blue-600'>MAKE WITHDRAW</h2>
         <BiMoneyWithdraw size={35} color='blue'/> 
            </div>
                <div>
                <label className='font-bold text-gray-700 text-lg'>Amount</label>
                <input className=' border-gray-400 ml-2 border-2 p-2 rounded-md' type="number" value={withdrawalAmount} onChange={(e) => setWithdrawalAmount(e.target.value)} />
                </div>

                <div className='ml-8'>
                    <label className='font-bold text-gray-700 text-lg'>Your Name</label>
                <input className=' border-gray-400 ml-2 border-2 p-2 rounded-md' type="text" value={withdrawalName} onChange={(e) => setWithdrawalName(e.target.value)} />
                </div>

                <div className='ml-8'>
                    <label className='font-bold text-gray-700 text-lg'>Purpose</label>
                <input className=' border-gray-400 ml-2 p-2 border-2 rounded-md' type="text" value={withdrawalReason} onChange={(e) => setWithdrawalReason(e.target.value)} />
                </div>

                <button className='font-bold text-white bg-blue-600 capitalize hover:border-blue-600 hover:text-blue-600 hover:bg-white border-2 duration-700 active:bg-green-600 p-2 w-32 h-14 ml-10 rounded-md' onClick={handleWithdraw}><BiMoneyWithdraw size={20} className='absolute' />  Withdraw </button>
        </div>
      </div>
      <div className='flex'>
        <div className='h-[400px] overflow-scroll w-[60%]'>
          <h2 className='font-bold text-start text-3xl my-8 pl-2 text-[#ffa21d]'>Remittance Record</h2>
            {/* <ul className='bg-white shadow-lg p-2'> */}
            <table className="min-w-full divide-y-2 divide-x-2 divide-blue-600">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-100 text-left text-lg leading-4 font-medium text-gray-700 uppercase tracking-wider">Name</th>
                  <th className="px-6 py-3 bg-gray-100 text-left text-lg leading-4 font-medium text-gray-700 uppercase tracking-wider">Amount</th>
                  <th className="px-6 py-3 bg-gray-100 text-left text-lg leading-4 font-medium text-gray-700 uppercase tracking-wider">Prayer</th>
                  <th className="px-6 py-3 bg-gray-100 text-left text-lg leading-4 font-medium text-gray-700 uppercase tracking-wider">Date</th>
                  <th className="px-6 py-3 bg-gray-100 text-left text-lg leading-4 font-medium text-gray-700 uppercase tracking-wider">Contact</th>
                  <th className="px-6 py-3 bg-gray-100 text-left text-lg leading-4 font-medium text-gray-700 uppercase tracking-wider">Transaction ID</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y-2 divide-gray-400">
            {directTithes.map((tithing) => (
              <tr key={tithing.id} className="bg-gray-100">
            <td className="px-6 py-4 whitespace-no-wrap">{tithing.payeeName}</td>
            <td className="px-6 py-4 whitespace-no-wrap">{tithing.PayeeAmount}</td>
            <td className="px-6 py-4 whitespace-no-wrap">{tithing.payeePrayer}</td>
            <td className="px-6 py-4 whitespace-no-wrap">{tithing.createdAt.toDate().toLocaleString()}</td>
            <td className="px-6 py-4 whitespace-no-wrap">{tithing.payeePhoneNumber}</td>
            <td className="px-6 py-4 whitespace-no-wrap">{tithing.transactionId}</td>
          </tr>
        ))}
        </tbody>
        </table>
          {/* </ul> */}
        </div>
        <div className='w-[50%] grid justify-end'>
            <div className=' shadow-lg p-5 h-44 w-96 shadow-blue-200 bg-[#3ec9d6] rounded-md text-center'>
              <FaBitcoin size={50} color='white' className='self-center' />
            <h2 className='font-extrabold text-5xl -mt-5 text-white m-4'>Balance</h2>
            <h2 className='font-extrabold text-2xl text-white m-4'> {currencyFormatter.format(directTitheBalance)}</h2>
            </div>

            <div className='mt-5 shadow-lg p-5 h-44 w-96 shadow-blue-200 bg-[#ffa21d] rounded-md text-center'>
              <BiMoneyWithdraw size={50} color='white' className='self-center' />
            <h2 className='font-extrabold text-4xl -mt-5 text-white m-4'>Withdrawals</h2>
            <h2 className='font-extrabold text-2xl text-white m-4'> {currencyFormatter.format(totalWithdrawnAmount)}</h2>
            </div>
        </div>
      </div>
      {/* <h2 className='font-extrabold text-xl text-blue-600 m-4'>Total Amount Withdrawn: {currencyFormatter.format(totalWithdrawnAmount)}</h2> */}
      <hr className='mt-28 shadow-lg h-2 bg-gray-500 border-2'/>
        <h1 className="text-2xl font-semibold m-20 text-center capitalize text-blue-600 mb-10">Withdrawal Records</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y-2 divide-gray-800">
            <thead>
                <tr>
                    <th className="px-6 py-3 bg-gray-100 text-left text-md leading-4 font-medium text-gray-700 uppercase tracking-wider">Withdrawn By</th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-md leading-4 font-medium text-gray-700 uppercase tracking-wider">Amount</th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-md leading-4 font-medium text-gray-700 uppercase tracking-wider">Purpose</th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-md leading-4 font-medium text-gray-700 uppercase tracking-wider">Date</th>
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-400">
            {directTitheWithdrawals.map((withdrawal) => (
                <tr key={withdrawal.id}>
                <td className="px-6 py-4 whitespace-no-wrap"> {withdrawal.name}</td>
                <td className="px-6 py-4 whitespace-no-wrap"> {currencyFormatter.format(withdrawal.amount)}</td>
                <td className="px-6 py-4 whitespace-no-wrap"> {withdrawal.reason}</td>
                <td className="px-6 py-4 whitespace-no-wrap"> {withdrawal.createdAt.toDate().toLocaleString()}</td>
                {/* Add more details here */}
                </tr>
            ))}
            </tbody>
        </table>
      </div>
    </div>
  );
};

export default Audio;
