import React, { useEffect, useState } from "react";
import { Table, Space, Modal, Button, Input, Select } from "antd";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  addDoc,
  query,
  where,
  getDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
// import { useAuth } from '../Context/Context';
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";

const { Option } = Select;
const ViewRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQueryModalOpen, setIsQueryModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [queryInput, setQueryInput] = useState("");
  const [totalPages, setTotalPages] = useState(1);

  const [email, setEmail] = useState();
  const [message, setMessage] = useState();

  const formatTimestamp = (timestamp) => {
    if (timestamp) {
      const date = timestamp?.toDate(); // Convert to JavaScript Date
      return `${date?.toLocaleDateString()} ${date?.toLocaleTimeString()}`;
    } else {
      return "Invalid Timestamp";
    }
  };
  // const { user } = useAuth()

  const showModal = (request) => {
    setSelectedRequest(request);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsQueryModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsQueryModalOpen(false);
  };

  const showQueryModal = () => {
    setIsQueryModalOpen(true);
  };



  const handleApprove = async (requestId) => {
    try {
      // TODO: Add logic to set the 'approved' field to true in Firebase
      const requestDocRef = doc(db, "request", requestId);
      await updateDoc(requestDocRef, { approved: true });

      // Your EmailJS service ID, template ID, and Public Key
      const serviceId = "service_5xh2f2c";
      const templateId = "template_lgm3m15";
      const publicKey = "GuQuWIaPwgvPUkwQ6";

      // Create a new object that contains dynamic template params
      const templateParams = {
        name: "Dumbari",
        email: requests.from,
        to_name: "Web Wizard",
        // message: message,
      };

      // Send the email using EmailJS
      emailjs
        .send(serviceId, templateId, templateParams, publicKey)
        .then((response) => {
          console.log("Email sent successfully!", response);
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
      toast.success(`Request with ID ${requestId} has been approved.`);
    } catch (error) {
      console.error("Error approving request:", error);
    }
  };

  const handleQuery = async () => {
    try {
      if (!selectedRequest) {
        console.error("No request selected for query.");
        return;
      }

      // Fetch the selected request data
      const requestDoc = await getDoc(doc(db, "request", selectedRequest.id));
      const requestData = { id: requestDoc.id, ...requestDoc.data() };

      // Save the query to the 'queries' collection
      await addDoc(collection(db, "queries"), {
        queryInput,
        timestamp: new Date(),
        // userId: user.uid, // assuming user is authenticated
        requestData,
      });

      // Close the query modal
      setIsQueryModalOpen(false);

      const serviceId = "service_5xh2f2c";
      const templateId = "template_zop7kdf";
      const publicKey = "GuQuWIaPwgvPUkwQ6";

      // Create a new object that contains dynamic template params
      const templateParams = {
        name: "Dumbari",
        email: "dumbari@ooobcs.org",
        to_name: "Web Wizard",
        message: message,
      };

      // Send the email using EmailJS
      emailjs
        .send(serviceId, templateId, templateParams, publicKey)
        .then((response) => {
          console.log("Email sent successfully!", response);
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
      toast.success("query sent");
    } catch (error) {
      console.error("Error handling query:", error);
    }
  };

  // useEffect(() => {
  //   const fetchRequests = async () => {
  //     setLoading(true);
  //     try {
  //       const querySnapshot = await getDocs(
  //         query(collection(db, "request"), orderBy("timestamp", "desc"))
  //       );
  
  //       const requestData = querySnapshot.docs
  //         .map((doc) => ({
  //           id: doc.id,
  //           ...doc.data(),
  //         }))
  //         .filter((request) => !request.approved);
  
  //       setRequests(requestData);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching requests:", error);
  //       setLoading(false);
  //     }
  //   };
  
  //   fetchRequests();
  // }, []);
  

  const columns = [
    {
      title: "Fellowship",
      dataIndex: "fellowship",
      width: 150,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: 120,
      render: (text, record) => (
        <span className="font-bold text-lg">
          {record?.amount
            ? record?.amount?.toLocaleString("en-US")
            : "No date available"}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      width: 120,
      key: "date",
      render: (text, record) => (
        <span className="">
          {record?.date
            ? formatTimestamp(record?.date)
            : "No date available"}
        </span>
      ),
    },

    {
      title: "Topic",
      dataIndex: "topic",
      width: 300,
      render: (text) => `${text.substring(0, 40)}......`,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => showModal(record)} className="bg-blue-500 text-white">
            View
          </Button>
        </Space>
      ),
      width: 200,
    },
    {
      title: "Approval Status",
      dataIndex: "approved",
      render: (text, record) => (
        <Space size="middle">
          {record.approved ? (
            <span style={{ color: "green" }}>
              <CheckCircleOutlined />
            </span>
          ) : (
            <span style={{ color: "red" }}>
              <CloseCircleOutlined />
            </span>
          )}
        </Space>
      ),
      width: 150,
      marginLeft: 20,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = "service_5xh2f2c";
    const templateId = "template_zop7kdf";
    const publicKey = "GuQuWIaPwgvPUkwQ6";

    // Create a new object that contains dynamic template params
    const templateParams = {
      // name: user.firstName,
      email: requests.from,
      to_name: "Web Wizard",
      // message: message,
    };

    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const handleSearch = (value) => {
    setQueryInput(value);
  };

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(
          query(collection(db, "request"), orderBy("date", "desc"))
        );

        const requestData = querySnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((request) => !request.approved); // Display only requests with approved set to false

        setRequests(requestData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching requests:", error);
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  
  const filteredRequests = requests.filter((request) => {
    // Filter based on the search input (you can customize this logic)
    return (
      (request.fellowship &&
        request.fellowship.toLowerCase().includes(queryInput.toLowerCase())) ||
      (request.date &&
        request.date.toLowerCase().includes(queryInput.toLowerCase())) ||
      (request.topic &&
        request.topic.toLowerCase().includes(queryInput.toLowerCase()))
    );
  });

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    const printContent = document.getElementById("print-content");

    if (printWindow && printContent) {
      printWindow.document.write(
        "<html><head><title>Print</title></head><body>"
      );
      printWindow.document.write(printContent.innerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        //   marginLeft: "-8%",`
        // width: "115%",
      }}
      className="ml-20"
    >
      <h1>View Requests</h1>
      <Search
        placeholder="Search requests..."
        // onSearch={handleSearch}
        onChange={(e) => handleSearch(e.target.value)}
        // value={searchText}
        style={{ width: "80%", maxWidth: "300px", marginBottom: "1rem" }}
        // enterButton
      />
<Table
        loading={loading}
        columns={columns}
        dataSource={filteredRequests}
        pagination={{
          pageSize: 10,
          
        }}
        scroll={{ x: "max-content" }}
        className="w-[95%] p-1 cursor-pointer"
      ></Table>

      <Modal
        title="Request Details"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="70%"
      >
        {selectedRequest && (
          <div className="p-4 w-[100%]">
            <div className="mb-4">
              <p className="text-lg font-bold">Date: { formatTimestamp(selectedRequest.date)}</p>
              <p className="text-lg font-bold">
                Fellowship: {selectedRequest.fellowship}
              </p>
            </div>
            <div className="mb-4">
              <p className="text-xl font-bold">
                Title: {selectedRequest.topic}
              </p>
              <p className="text-xl font-bold">
                Amount: {selectedRequest.amount}
              </p>
            </div>
            <div className="mb-4">
              <p>The Supervisor BCS Establisments / Departments</p>
              <p className="mb-2">
                I hope this letter finds you well. We would like to bring to
                your attention the following request details:
              </p>
              <ul className="list-disc pl-6 mb-4">
                {selectedRequest.attachments && (
                  <ul className="list-disc">
                    <li>
                      <strong>Attachments:</strong>
                      {selectedRequest.attachments.length === 1 ? (
                        <a
                          href={selectedRequest.attachments[0].url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline ml-1"
                        >
                          {selectedRequest.attachments[0].name}
                        </a>
                      ) : (
                        <Select
                          style={{ width: "30%", marginLeft: 8 }}
                          placeholder={`Select an attachment (${selectedRequest.attachments.length} available)`}
                          onChange={(value) => {
                            const selectedAttachment =
                              selectedRequest.attachments.find(
                                (attachment) => attachment.name === value
                              );
                            window.open(selectedAttachment.url, "_blank");
                          }}
                        >
                          {selectedRequest.attachments.map(
                            (attachment, index) => (
                              <Option key={index} value={attachment.name}>
                                {attachment.name}
                              </Option>
                            )
                          )}
                        </Select>
                      )}
                    </li>
                  </ul>
                )}

                <li>
                  <strong>Head :</strong> {selectedRequest.fellowshipHead} / {selectedRequest.fellowshipHeadnum}
                </li>
                <li>
                  <strong>Finance Officer : </strong>{" "}
                  {selectedRequest.financeOfficer} / {selectedRequest.financeOfficernum}
                </li>
                <li>
                  <strong>Treasurer : </strong> {selectedRequest.treasurer} / {selectedRequest.treasurernum}
                </li>
                {/* <li><strong>authorizedBy</strong> {selectedRequest.authorizedBy}</li> */}
                <li>
                  <strong>Details : </strong>{" "}
                  {selectedRequest.body && (
                    <div
                      dangerouslySetInnerHTML={{ __html: selectedRequest.body }}
                    ></div>
                  )}
                </li>
                <li>
                  <strong>Last Requisition Date : </strong>{" "}
                  {selectedRequest.lastRequisitionDate}
                </li>

                <li>
                  <strong>Account Name: </strong>{" "}
                  {selectedRequest.accountName}
                </li>
                <li>
                  <strong>Account Number : </strong>{" "}
                  {selectedRequest.accountNum}
                </li>
                <li>
                  <strong>Bank : </strong>{" "}
                  {selectedRequest.bankName}
                </li>
                <li>
                  <strong>Amount : </strong>{" "}
                  {selectedRequest.amount?.toLocaleString("en-US")}
                </li>
                {/* Add more details as needed */}
              </ul>
              <p className="mb-4">
                We kindly request your approval for this matter. Your prompt
                attention to this request is highly appreciated.
              </p>
              <p className="mb-4">Thank you for your cooperation.</p>
              <p>Sincerely,</p>
              <p className="font-bold">{selectedRequest.fellowship}</p>
            </div>

            <div className="ml-[69%] -mt-160">
              <p className="font-bold">
                -------------------------------------------
              </p>
              <p className="font-bold">HIS HOLINESS OLUMBA OLUMBA OBU</p>
              <h5>
                The King of Kings and Lord of Lords
                <br />
                Leader Unified Universal Theocracy/
                <br />
                Head of Administration and Sole Signatory BCS Worldwide
              </h5>
            </div>
            <div className="flex justify-center">
              <Button
                type="primary"
                onClick={() => handleApprove(selectedRequest.id)}
                className="bg-green-500 mr-2"
              >
                Accept
              </Button>
              <Button
                type="primary"
                onClick={showQueryModal}
                className="bg-gray-600"
              >
                Query
              </Button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        title="Query Input"
        visible={isQueryModalOpen}
        onOk={handleQuery}
        onCancel={handleCancel}
      >
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter your query here..."
          className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
          style={{ wordWrap: "break-word", maxWidth: "300px" }} // Adjust the maxWidth value as needed
        />
      </Modal>
    </div>
  );
};

export default ViewRequests;
