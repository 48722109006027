const sidebarNav = [
    {
        link: '/',
        section: 'home',
        icon: <i className='bx bx-home-alt'></i>,
        text: 'Dashboard'
    },
    {
        link: '/products',
        section: 'products',
        icon: <i className='bx bx-user'></i>,
        text: 'Employees'
    },

    {
        link: '/stats',
        section: 'hero',
        icon: <i className='bx bx-cube'></i>,
        text: 'Treasury'
    },
   
    {
        link: '/settings',
        section: 'settings',
        icon: <i className='bx bx-receipt'></i>,
        text: 'Departments'
    },
   
    {
        link: '/settings',
        section: 'settings',
        icon: <i className='bx bx-cog'></i>,
        text: 'Meeting'
    },
    {
        link: '/settings',
        section: 'settings',
        icon: <i className='bx bx-cog'></i>,
        text: 'Zoom Meeting'
    },
    {
        link: '/addToPayroll',
        section: 'settings',
        icon: <i className='bx bx-receipt'></i>,
        text: 'Payroll'
    },
    // {
    //     link: '/settings',
    //     section: 'settings',
    //     icon: <i className='bx bx-cog'></i>,
    //     text: 'Documents'
    // },
    // {
    //     link: '/settings',
    //     section: 'settings',
    //     icon: <i className='bx bx-cog'></i>,
    //     text: 'Email Templates'
    // },
    // {
    //     link: '/settings',
    //     section: 'settings',
    //     icon: <i className='bx bx-cog'></i>,
    //     text: 'Company Policy'
    // },
    // {
    //     link: '/settings',
    //     section: 'settings',
    //     icon: <i className='bx bx-cog'></i>,
    //     text: 'Messenger'
    // },

]

export default sidebarNav