import React, { useEffect, useRef, useState } from "react";
import "./sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import { images } from "../../constants";
import sidebarNav from "../../configs/sidebarNav";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import { toast } from "react-toastify";
import { AiOutlineHome } from "react-icons/ai";
import { BsPeopleFill } from "react-icons/bs";
import { MdOutlinePayments } from "react-icons/md";
import { MdGroups } from "react-icons/md";
import { GiOpenTreasureChest } from "react-icons/gi";
import { FcDepartment } from "react-icons/fc";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useUser } from "../user-info/UserContext";
import Son from "../../assets/logo.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { Button, Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import ButtonGroup from "antd/es/button/button-group";
import { CiLogout } from "react-icons/ci";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { FaCodePullRequest } from "react-icons/fa6";
import { VscRequestChanges } from "react-icons/vsc";
import { FcAcceptDatabase } from "react-icons/fc";
import { MdGppGood } from "react-icons/md";

const { Sider } = Layout;

const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const { userDepartment } = useUser();

  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropOpen, setDropOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  const [departments, setDepartments] = useState([]);
  const [fellowships, setFellowships] = useState([]);

  useEffect(() => {
    const fetchFellowship = async () => {
      const querySnapshot = await getDocs(collection(db, "BCS"));
      const fellowshipData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Filter departments by category (e.g., "Department")
      const filteredFellowships = fellowshipData
        .filter((department) => department.category === "Department")
        .sort((a, b) => a.department_name?.localeCompare(b.department_name));

      setFellowships(filteredFellowships);
      // console.log(fellowshipData)
    };

    fetchFellowship();
  }, []);

  useEffect(() => {
    const fetchDepartments = async () => {
      const querySnapshot = await getDocs(collection(db, "BCS"));
      const departmentsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        staffCount: 0,
      }));

      // Filter departments by category (e.g., "Department")
      const filteredDepartments = departmentsData.filter(
        (department) => department.category === "Department"
      );

      // Sort filtered departments data
      const sortedDepartments = departmentsData.sort((a, b) =>
        a.department_name?.localeCompare(b.department_name)
      );

      // Fetch staff count for each department
      const staffQuerySnapshot = await getDocs(collection(db, "Staff"));
      const staffData = staffQuerySnapshot.docs.map((doc) => doc.data());

      // Count staff members for each department
      for (const department of filteredDepartments) {
        const staffInDepartment = staffData.filter(
          (staff) => staff.department === department.department_name
        );
        department.staffCount = staffInDepartment.length;
      }

      setDepartments(sortedDepartments);
      // setDepartments(departmentsData);
    };

    fetchDepartments();
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toDropdown = () => {
    setDropOpen(!isDropOpen);
  };

  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNav.findIndex((item) => item.section === curPath);

    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  const closeSidebar = () => {
    document.querySelector(".main__content").style.transform =
      "scale(1) translateX(0)";
    setTimeout(() => {
      document.body.classList.remove("sidebar-open");
      document.querySelector(".main__content").style = "";
    }, 500);
  };

  return (
    <>
      <Sider
        style={{ backgroundColor: "#fff", position: "fixed", height: "100%" }}
        collapsed={collapsed}
        // collapsible
        // trigger={null}
      >
        <div className="">
          <Button
            type="text"
            className="toggle  ml-20"
            onClick={() => setCollapsed(!collapsed)}
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          />
        </div>
        <>
          <Link to={"/"} className="sidebar__logo">
            <img
              src="https://static.wixstatic.com/media/ad376a_fcba4bbfa9434fc5abc2ff7f7b587664~mv2.png/v1/fill/w_420,h_184,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ad376a_fcba4bbfa9434fc5abc2ff7f7b587664~mv2.png"
              alt=""
              style={{ width: "100%", height: "auto" }}
              className="px-3"
            />
          </Link>

          <Menu theme="light" mode="vertical" className="-mt-9">
            {[
              "others",
              "Creben",
              "Abanimo",
              "Akribay",
              "Kodeledo",
              "Ogonla",
              "Kwoyosek",
              "Nignasabu",
              "Platarben",
              "Boyoad",
              "Jigoba",
              "Katkakad",
              "Sokeza",
            ].includes(userDepartment?.role) && (
              <>
                <Menu.Item key="department" icon={<FcDepartment />}>
                  <Link to={`/department/${userDepartment?.department}`}>
                    {userDepartment?.name}
                  </Link>
                </Menu.Item>

                <Menu.Item
                  key="2"
                  onClick={() => {
                    auth
                      .signOut()
                      .then((res) => toast.success("You have been logged out!"))
                      .catch((err) => {
                        toast.error("An Error Occurred!");
                      });
                  }}
                  icon={<CiLogout />}
                >
                  <Link to={"/"}>Logout</Link>
                </Menu.Item>
              </>
            )}

            {userDepartment?.role === "TitheOffice" && (
              <>

{
  auth?.currentUser?.email === "bcstreasury@ooobcs.org" &&
                <Menu.Item
                  key="dashboard"
                  onClick={closeSidebar}
                  icon={<AiOutlineHome />}
                >
                  <Link to="/dashboard">Dashboard</Link>
                </Menu.Item>
}

                <Menu.Item
                  key="products"
                  onClick={closeSidebar}
                  icon={<BsPeopleFill />}
                >
                  <Link to="/products">Staffs</Link>
                </Menu.Item>

{
  auth?.currentUser?.email === "bcstreasury@ooobcs.org" &&
  <>
                <Menu.Item
                  key="finance"
                  onClick={closeSidebar}
                  icon={<GiOpenTreasureChest />}
                >
                  <Link to="/finance">Finance</Link>
                </Menu.Item>


                <Menu.Item
                  key="mandate"
                  onClick={closeSidebar}
                  icon={<GiOpenTreasureChest />}
                >
                  <Link to="/mandate">Mandate</Link>
                </Menu.Item>

                <Menu.Item
                  key="treasury"
                  onClick={closeSidebar}
                  icon={<GiOpenTreasureChest />}
                >
                  <Link to="/Treasury">Withdrawals</Link>
                </Menu.Item>



                <Menu.Item key="bcs">
                  <Link
                    to="/bcs"
                    onClick={toggleDropdown}
                    icon={<FcDepartment />}
                  >
                    BCS
                  </Link>

                  {/* {isDropdownOpen && (
                    <div style={{ marginLeft: "20px", width: "80px" }}>
                      <ul style={{ display: "flex", flexWrap: "wrap" }}>
                        {departments.map((department) => (
                          <li
                            key={department.id}
                            className="py-2 w-56 hover:text-blue-400"
                          >
                            <Link to={`/department/${department?.id}`}>
                              {department?.department_name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )} */}
                </Menu.Item>

                <Menu.Item key="departs" icon={<MdGroups />}>
                  <Link to="/departs" onClick={toDropdown}>
                    Departments
                  </Link>

                  {/* {isDropOpen && (
                    <div style={{ marginLeft: "20px", width: "80px" }}>
                      <ul style={{ display: "flex", flexWrap: "wrap" }}>
                        {fellowships.map((department) => (
                          <li
                            key={department.id}
                            className="py-2 w-56 hover:text-blue-400"
                          >
                            <Link to={`/department/${department?.id}`}>
                              {department?.department_name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )} */}
                </Menu.Item>
                </>
}
                <Menu.Item
                  key="ict"
                  onClick={closeSidebar}
                  icon={<MdOutlinePayments />}
                >
                  <Link to="/ict">PayRoll</Link>
                </Menu.Item>

                <Menu.Item
                  key="signOut"
                  onClick={() => {
                    auth
                      .signOut()
                      .then((res) => toast.success("You have been logged out!"))
                      .catch((err) => {
                        toast.error("An Error Occurred!");
                      });
                  }}
                  icon={<CiLogout />}
                >
                  <Link to={"/"}>Logout</Link>
                </Menu.Item>
              </>
            )}

            {userDepartment?.role === "Ict" && (
              <>
                <Menu.Item
                  key="dashboard"
                  onClick={closeSidebar}
                  icon={<AiOutlineHome />}
                >
                  <Link to="/dashboard">Dashboard</Link>
                </Menu.Item>

                <Menu.Item
                  key="products"
                  onClick={closeSidebar}
                  icon={<BsPeopleFill />}
                >
                  <Link to="/products">Staffs</Link>
                </Menu.Item>

                <Menu.Item
                  key="financeA"
                  onClick={closeSidebar}
                  icon={<GiOpenTreasureChest />}
                >
                  <Link to="/financeA">Finance Attempts</Link>
                </Menu.Item>

                <Menu.Item
                  key="finance"
                  onClick={closeSidebar}
                  icon={<FaMoneyCheckDollar />}
                >
                  <Link to="/finance">Finance</Link>
                </Menu.Item>
                <Menu.Item
                  key="mandate"
                  onClick={closeSidebar}
                  icon={<GiOpenTreasureChest />}
                >
                  <Link to="/mandate">Mandate</Link>
                </Menu.Item>
                <Menu.Item
                  key="treasury"
                  onClick={closeSidebar}
                  icon={<GiOpenTreasureChest />}
                >
                  <Link to="/Treasury">Withdrawals</Link>
                </Menu.Item>
                <Menu.Item
                  key="viewrequisition"
                  onClick={closeSidebar}
                  icon={<VscRequestChanges />}
                >
                  <Link to="/viewrequisition">Requsitions</Link>
                </Menu.Item>

                <Menu.Item
                  key="viewretirements"
                  onClick={closeSidebar}
                  icon={<FaCodePullRequest />}
                >
                  <Link to="/viewretirements">Retirements</Link>
                </Menu.Item>

                <Menu.Item
                  key="acceptedments"
                  onClick={closeSidebar}
                  icon={<FcAcceptDatabase />}
                >
                  <Link to="/acceptedments">Accepted Retirements</Link>
                </Menu.Item>
                <Menu.Item
                  key="accepted"
                  onClick={closeSidebar}
                  icon={<MdGppGood />}
                >
                  <Link to="/accepted">Accepted Requisitions</Link>
                </Menu.Item>

                <Menu.Item
                  key="Signup"
                  onClick={closeSidebar}
                  icon={<BsPeopleFill />}
                >
                  <Link to="/Signup">Create User</Link>
                </Menu.Item>

                <Menu.Item key="bcs" icon={<FcDepartment />}>
                  <Link to="/bcs">BCS</Link>

                  {/* {isDropdownOpen && (
              <div style={{ marginLeft: "20px", width: "80px" }}>
                <ul style={{ display: "flex", flexWrap: "wrap" }}>
                  {departments.map((department) => (
                    <li key={department.id} className="py-2 w-56 hover:text-blue-400">
                      <Link to={`/department/${department?.id}`}>
                        {department?.department_name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )} */}
                </Menu.Item>

                <Menu.Item key="departs" icon={<MdGroups />}>
                  <Link to="/departs">Departments</Link>

                  {/* {isDropOpen && (
              <div style={{ marginLeft: "20px", width: "80px" }}>
                <ul style={{ display: "flex", flexWrap: "wrap" }}>
                  {fellowships.map((department) => (
                    <li key={department.id} className="py-2 w-56 hover:text-blue-400">
                      <Link to={`/department/${department?.id}`}>
                        {department?.department_name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )} */}
                </Menu.Item>

                <Menu.Item
                  key="ict"
                  onClick={closeSidebar}
                  icon={<MdOutlinePayments />}
                >
                  <Link to="/ict">PayRoll</Link>
                </Menu.Item>

                <Menu.Item
                  key="/"
                  onClick={() => {
                    auth
                      .signOut()
                      .then((res) => toast.success("You have been logged out!"))
                      .catch((err) => {
                        toast.error("An Error Occurred!");
                      });
                  }}
                  icon={<CiLogout />}
                >
                  <Link to={"/"}>Logout</Link>
                </Menu.Item>
              </>
            )}

            {userDepartment?.role === "AuditUnit" && (
              <>
                <Menu.Item
                  key="0"
                  onClick={closeSidebar}
                  icon={<AiOutlineHome />}
                >
                  <Link to="/viewretirements">Retirements</Link>
                </Menu.Item>

                <Menu.Item
                  key="1"
                  onClick={() => {
                    auth
                      .signOut()
                      .then((res) => toast.success("You have been logged out!"))
                      .catch((err) => {
                        toast.error("An Error Occurred!");
                      });
                  }}
                  icon={<CiLogout />}
                >
                  <Link to={"/"}>Logout</Link>
                </Menu.Item>
              </>
            )}

            {[
              "sokezaaudit",
              "Katkakadaudit",
              "jigobaaudit",
              "boyoadaudit",
              "Platarbenaudit",
              "Nignasabuaudit",
              "Kwoyosekaudit",
              "Ogonlaaudit",
              "Kodeledoaudit",
              "Akribayaudit",
              "Crebenaudit",
              "Abanimoaudit",
            ].includes(userDepartment?.role) && (
              <>
                <Menu.Item
                  key="0"
                  onClick={closeSidebar}
                  icon={<AiOutlineHome />}
                >
                  <Link to="/stateretirement">Retirements</Link>
                </Menu.Item>

                <Menu.Item
                  key="1"
                  onClick={() => {
                    auth
                      .signOut()
                      .then((res) => toast.success("You have been logged out!"))
                      .catch((err) => {
                        toast.error("An Error Occurred!");
                      });
                  }}
                  icon={<CiLogout />}
                >
                  <Link to={"/"}>Logout</Link>
                </Menu.Item>
              </>
            )}

            {userDepartment?.role === "Supervisor" && (
              <>
                <Menu.Item
                  key="dashboard"
                  onClick={closeSidebar}
                  icon={<AiOutlineHome />}
                >
                  <Link to="/dashboard">Dashboard</Link>
                </Menu.Item>

                <Menu.Item
                  key="1"
                  onClick={closeSidebar}
                  icon={<BsPeopleFill />}
                >
                  <Link to="/products">Staffs</Link>
                </Menu.Item>
                <Menu.Item
                  key="finance"
                  onClick={closeSidebar}
                  icon={<FaMoneyCheckDollar />}
                >
                  <Link to="/finance">Finance</Link>
                </Menu.Item>

                <Menu.Item
                  key="mandate"
                  onClick={closeSidebar}
                  icon={<GiOpenTreasureChest />}
                >
                  <Link to="/mandate">Mandate</Link>
                </Menu.Item>

                <Menu.Item key="bcs" icon={<FcDepartment />}>
                  <Link to="/bcs">BCS</Link>
                  </Menu.Item>

                <Menu.Item key="2" icon={<MdGroups />}>
                  <Link to="/departs">Departments</Link>

                  {/* {isDropOpen && (
                    <div style={{ marginLeft: "20px", width: "80px" }}>
                      <ul style={{ display: "flex", flexWrap: "wrap" }}>
                        {fellowships.map((department) => (
                          <li
                            key={department.id}
                            className="py-2 w-56 hover:text-blue-400"
                          >
                            <Link to={`/department/${department?.id}`}>
                              {department?.department_name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )} */}
                </Menu.Item>

                <Menu.Item
                  key="3"
                  onClick={toDropdown}
                  icon={<GiOpenTreasureChest />}
                >
                  <Link to="/departbalance">All Dept Balance</Link>
                </Menu.Item>
                <Menu.Item
                  key="treasury"
                  onClick={closeSidebar}
                  icon={<GiOpenTreasureChest />}
                >
                  <Link to="/Treasury">Withdrawals</Link>
                </Menu.Item>
                <Menu.Item
                  key="viewrequisition"
                  onClick={closeSidebar}
                  icon={<VscRequestChanges />}
                >
                  <Link to="/viewrequisition">Requsitions</Link>
                </Menu.Item>

                <Menu.Item
                  key="viewretirements"
                  onClick={closeSidebar}
                  icon={<FaCodePullRequest />}
                >
                  <Link to="/viewretirements">Retirements</Link>
                </Menu.Item>

                <Menu.Item
                  key="acceptedments"
                  onClick={closeSidebar}
                  icon={<FcAcceptDatabase />}
                >
                  <Link to="/acceptedments">Accepted Retirements</Link>
                </Menu.Item>
                <Menu.Item
                  key="accepted"
                  onClick={closeSidebar}
                  icon={<MdGppGood />}
                >
                  <Link to="/accepted">Accepted Requisitions</Link>
                </Menu.Item>
                <Menu.Item
                  key="6"
                  onClick={() => {
                    auth
                      .signOut()
                      .then((res) => toast.success("You have been logged out!"))
                      .catch((err) => {
                        toast.error("An Error Occurred!");
                      });
                  }}
                  icon={<CiLogout />}
                >
                  <Link to={"/"}>Logout</Link>
                </Menu.Item>
              </>
            )}
          </Menu>
          <div className="md:hidden"></div>
        </>
      </Sider>
    </>
  );
};

export default Sidebar;

// <Menu
// theme="light"
// mode="vertical"
// selectedKeys={[`${activeIndex}`]}
// >
// {userDepartment?.role === "user" && (
//   <>
//     <Menu.Item key="1" icon={<FcDepartment />}>
//       <Link to={`/department/${userDepartment?.department}`}>
//         {userDepartment?.name}
//       </Link>
//     </Menu.Item>

//     <Menu.Item
//       key="2"
//       onClick={() => {
//         auth
//           .signOut()
//           .then((res) =>
//             toast.success("You have been logged out!")
//           )
//           .catch((err) => {
//             toast.error("An Error Occurred!");
//           });
//       }}
//     >
//       <Link to={"/"}>
//         {/* <i className="bx bx-log-out"></i> */}
//         Logout
//       </Link>
//     </Menu.Item>
//   </>
// )}

// {userDepartment?.role === "TitheOffice" && (
//   <>
//     <Menu.Item key="0" onClick={closeSidebar}>
//       <Link to="/dashboard">
//         <AiOutlineHome style={{ width: "80%", height: "96px" }} />
//         <div>Dashboard</div>
//       </Link>
//     </Menu.Item>

//     <Menu.Item key="1" onClick={closeSidebar}>
//       <Link to="/products">
//         <BsPeopleFill style={{ width: "80%", height: "96px" }} />
//         <div>Staffs</div>
//       </Link>
//     </Menu.Item>

//     <Menu.Item key="2" onClick={closeSidebar}>
//       <Link to="/finance">
//         <GiOpenTreasureChest
//           style={{ width: "80%", height: "96px" }}
//         />
//         <div>Finance</div>
//       </Link>
//     </Menu.Item>

//     <Menu.Item key="3" onClick={closeSidebar}>
//       <Link to="/Signup">
//         <BsPeopleFill style={{ width: "80%", height: "96px" }} />
//         <div>Create User</div>
//       </Link>
//     </Menu.Item>

//     <Menu.Item key="4">
//       <Link to="/bcs" onClick={toggleDropdown}>
//         <FcDepartment style={{ width: "80%", height: "96px" }} />
//         <div className="cursor-pointer flex">BCS</div>
//         {isDropdownOpen ? (
//           <BiSolidUpArrow />
//         ) : (
//           <BiSolidDownArrow />
//         )}
//       </Link>

//       {isDropdownOpen && (
//         <div style={{ marginLeft: "20px", width: "80px" }}>
//           <ul style={{ display: "flex", flexWrap: "wrap" }}>
//             {departments.map((department) => (
//               <li
//                 key={department.id}
//                 className="py-2 w-56 hover:text-blue-400"
//               >
//                 <Link to={`/department/${department?.id}`}>
//                   {department?.department_name}
//                 </Link>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//     </Menu.Item>

//     <Menu.Item key="5">
//       <Link to="/departs" onClick={toDropdown}>
//         <MdGroups style={{ width: "80%", height: "96px" }} />
//         <div className="cursor-pointer flex">Departments</div>
//         {isDropOpen ? <BiSolidUpArrow /> : <BiSolidDownArrow />}
//       </Link>

//       {isDropOpen && (
//         <div style={{ marginLeft: "20px", width: "80px" }}>
//           <ul style={{ display: "flex", flexWrap: "wrap" }}>
//             {fellowships.map((department) => (
//               <li
//                 key={department.id}
//                 className="py-2 w-56 hover:text-blue-400"
//               >
//                 <Link to={`/department/${department?.id}`}>
//                   {department?.department_name}
//                 </Link>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//     </Menu.Item>

//     <Menu.Item key="6" onClick={closeSidebar}>
//       <Link to="/ict">
//         <MdOutlinePayments
//           style={{ width: "80%", height: "96px" }}
//         />
//         <div>PayRoll</div>
//       </Link>
//     </Menu.Item>

//     <Menu.Item
//       key="7"
//       onClick={() => {
//         auth
//           .signOut()
//           .then((res) =>
//             toast.success("You have been logged out!")
//           )
//           .catch((err) => {
//             toast.error("An Error Occurred!");
//           });
//       }}
//       icon={<CiLogout />}
//     >
//       <Link to={"/"}>Logout</Link>
//     </Menu.Item>
//   </>
// )}

// {userDepartment?.role === "Ict" && (
//   <>
//     <Menu.Item
//       key="0"
//       onClick={closeSidebar}
//       icon={<AiOutlineHome />}
//     >
//       <Link to="/dashboard">Dashboard</Link>
//     </Menu.Item>

//     <Menu.Item
//       key="1"
//       onClick={closeSidebar}
//       icon={<BsPeopleFill />}
//     >
//       <Link to="/products">Staffs</Link>
//     </Menu.Item>

//     <Menu.Item
//       key="2"
//       onClick={closeSidebar}
//       icon={<GiOpenTreasureChest />}
//     >
//       <Link to="/financeA">Finance Attempts</Link>
//     </Menu.Item>

//     <Menu.Item
//       key="3"
//       onClick={closeSidebar}
//       icon={<GiOpenTreasureChest />}
//     >
//       <Link to="/finance">Finance</Link>
//     </Menu.Item>

//     <Menu.Item
//       key="4"
//       onClick={closeSidebar}
//       icon={<BsPeopleFill />}
//     >
//       <Link to="/Signup">Create User</Link>
//     </Menu.Item>

//     <Menu.Item key="5" icon={<FcDepartment />}>
//       <Link to="/bcs" onClick={toggleDropdown}>
//         BCS
//       </Link>

//       {/* {isDropdownOpen && (
// <div style={{ marginLeft: "20px", width: "80px" }}>
//   <ul style={{ display: "flex", flexWrap: "wrap" }}>
//     {departments.map((department) => (
//       <li key={department.id} className="py-2 w-56 hover:text-blue-400">
//         <Link to={`/department/${department?.id}`}>
//           {department?.department_name}
//         </Link>
//       </li>
//     ))}
//   </ul>
// </div>
// )} */}
//     </Menu.Item>

//     <Menu.Item key="6" icon={<MdGroups />}>
//       <Link to="/departs" onClick={toDropdown}>
//         Departments
//         {isDropOpen ? <BiSolidUpArrow /> : <BiSolidDownArrow />}
//       </Link>

//       {/* {isDropOpen && (
// <div style={{ marginLeft: "20px", width: "80px" }}>
//   <ul style={{ display: "flex", flexWrap: "wrap" }}>
//     {fellowships.map((department) => (
//       <li key={department.id} className="py-2 w-56 hover:text-blue-400">
//         <Link to={`/department/${department?.id}`}>
//           {department?.department_name}
//         </Link>
//       </li>
//     ))}
//   </ul>
// </div>
// )} */}
//     </Menu.Item>

//     <Menu.Item
//       key="7"
//       onClick={closeSidebar}
//       icon={<MdOutlinePayments />}
//     >
//       <Link to="/ict">PayRoll</Link>
//     </Menu.Item>

//     <Menu.Item
//       key="8"
//       onClick={() => {
//         auth
//           .signOut()
//           .then((res) =>
//             toast.success("You have been logged out!")
//           )
//           .catch((err) => {
//             toast.error("An Error Occurred!");
//           });
//       }}
//       icon={<CiLogout />}
//     >
//       <Link to={"/"}></Link>
//     </Menu.Item>
//   </>
// )}

// {userDepartment?.role === "AuditUnit" && (
//   <>
//     <Menu.Item
//       key="1"
//       onClick={closeSidebar}
//       icon={<FcDepartment />}
//     >
//       <Link to="/viewretirements">Retirements</Link>
//     </Menu.Item>

//     <Menu.Item
//       key="1"
//       onClick={() => {
//         auth
//           .signOut()
//           .then((res) =>
//             toast.success("You have been logged out!")
//           )
//           .catch((err) => {
//             toast.error("An Error Occurred!");
//           });
//       }}
//     >
//       <Link to={"/"}>
//         <div>Logout</div>
//       </Link>
//     </Menu.Item>
//   </>
// )}

// {userDepartment?.role === "Supervisor" && (
//   <>
//     <Menu.Item
//       key="dashboard"
//       onClick={closeSidebar}
//       icon={<AiOutlineHome />}
//     >
//       <Link to="/dashboard">Dashboard</Link>
//     </Menu.Item>

//     <Menu.Item
//       key="1"
//       onClick={closeSidebar}
//       icon={<BsPeopleFill />}
//     >
//       <Link to="/products">Staffs</Link>
//     </Menu.Item>

//     <Menu.Item key="2">
//       <Link to="/departs" onClick={toDropdown}>
//         <MdGroups style={{ width: "80%", height: "96px" }} />
//         <div className="cursor-pointer flex">Departments</div>
//         {isDropOpen ? <BiSolidUpArrow /> : <BiSolidDownArrow />}
//       </Link>

//       {isDropOpen && (
//         <div style={{ marginLeft: "20px", width: "80px" }}>
//           <ul style={{ display: "flex", flexWrap: "wrap" }}>
//             {fellowships.map((department) => (
//               <li
//                 key={department.id}
//                 className="py-2 w-56 hover:text-blue-400"
//               >
//                 <Link to={`/department/${department?.id}`}>
//                   {department?.department_name}
//                 </Link>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//     </Menu.Item>

//     <Menu.Item
//       key="3"
//       onClick={toDropdown}
//       icon={<GiOpenTreasureChest />}
//     >
//       <Link to="/departbalance">All Dept Balance</Link>
//     </Menu.Item>

//     <Menu.Item
//       key="4"
//       onClick={closeSidebar}
//       icon={<GiOpenTreasureChest />}
//     >
//       <Link to="/viewrequisition">Requisitions</Link>
//     </Menu.Item>

//     <Menu.Item
//       key="5"
//       onClick={closeSidebar}
//       icon={<GiOpenTreasureChest />}
//     >
//       <Link to="/accepted">Accepted Requisitions</Link>
//     </Menu.Item>

//     <Menu.Item
//       key="6"
//       onClick={() => {
//         auth
//           .signOut()
//           .then((res) =>
//             toast.success("You have been logged out!")
//           )
//           .catch((err) => {
//             toast.error("An Error Occurred!");
//           });
//       }}
//       icon={<GiOpenTreasureChest />}
//     >
//       <Link to={"/"}>Logout</Link>
//     </Menu.Item>
//   </>
// )}
// </Menu>
