import React from "react";
import { Bar } from "react-chartjs-2";
import Box from "../components/box/Box";
import DashboardWrapper, {
  DashboardWrapperMain,
  DashboardWrapperRight,
} from "../components/dashboard-wrapper/DashboardWrapper";
import SummaryBox from "../components/summary-box/SummaryBox";
import { colors, data } from "../constants";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import OverallList from "../components/overall-list/OverallList";
import RevenueList from "../components/revenue-list/RevenueList";
import SummaryBox1 from "../components/summary-box/SummartBox1";
import SummaryBox2 from "../components/summary-box/SummaryBox2";
import SummaryBox3 from "../components/summary-box/SummaryBox3";
import SummaryBox4 from "../components/summary-box/SummaryBox4";
import SummaryBox5 from "../components/summary-box/SummaryBox5";
import SummaryBox6 from "../components/summary-box/SummaryBox6";
import SummaryBox7 from "../components/summary-box/SummaryBox7";
import Son from '../assets/002.jpg'
import Son1 from '../assets/oo1.jpg'
import SummaryBox8 from "../components/summary-box/SummaryBox8";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  return (
    <div>
   <div className="md:ml-36 w-full md:w-[80%]">
      <div className="flex flex-wrap -mx-2">
         <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2">
            <div className="mb">
              <SummaryBox />
            </div>
          </div>
           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2">
            <div className="mb">
              <SummaryBox1 />
            </div>
          </div>
           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2">
            <div className="mb">
              <SummaryBox2 />
            </div>
          </div>
           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2">
            <div className="mb">
              <SummaryBox3 />
            </div>
          </div>
           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2">
            <div className="mb">
              <SummaryBox4 />
            </div>
          </div>
           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2">
            <div className="mb">
              <SummaryBox5 />
            </div>
          </div>
           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2">
            <div className="mb">
              <SummaryBox6 />
            </div>
          </div>
           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2">
            <div className="mb">
              <SummaryBox7 />
            </div>
          </div>
           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2">
            <div className="mb">
              <SummaryBox8 />
            </div>
          </div>
        </div>

        <div className="-ml-5">
        <div className="w-full mt-4 md:mt-0">
            <Box>
              <RevenueByMonthsChart />
            </Box>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default Dashboard;

const RevenueByMonthsChart = () => {
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      yAxes: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    elements: {
      bar: {
        backgroundColor: colors.orange,
        borderRadius: 20,
        borderSkipped: "bottom",
      },
    },
  };

  const chartData = {
    labels: data.revenueByMonths.labels,
    datasets: [
      {
        label: "Revenue",
        data: data.revenueByMonths.data,
      },
    ],
  };
  return (
    <>
      <div className="title mb">Revenue by months</div>
      <div>
        <Bar options={chartOptions} data={chartData} height={`300px`} />
      </div>
    </>
  );
};
