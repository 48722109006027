import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { auth, db } from "../firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { FaBitcoin } from "react-icons/fa";
import { BiMoneyWithdraw } from "react-icons/bi";
import firebase from "../firebaseconfig";
import { useUser } from "../components/user-info/UserContext";
import {
  Button,
  Input,
  Table,
  Modal as AntModal,
  DatePicker,
  Form,
  Select,
} from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { SiLibrariesdotio } from "react-icons/si";
import { IoLibrarySharp } from "react-icons/io5";
import { CgLogIn } from "react-icons/cg";

const Option = Select;

const DepartmentScreen = () => {
  const { department_id } = useParams();
  const [departments, setDepartments] = useState([]);
  const [departmentData, setDepartmentData] = useState({});
  const [records, setRecords] = useState([]);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isPopupVisible1, setPopupVisible1] = useState(false);
  const [isPopVisible, setPopVisible] = useState(false);
  const [isPopsVisible, setPopsVisible] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedDepartmentfrom, setSelectedDepartmentfrom] = useState("");
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const { Search } = Input;

  const generateRandomString = (length) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleSearch = (value) => {
    const lowerCaseSearchTerm = value.toLowerCase();
    const filteredRecords = records.filter((record) => {
      return (
        (record.PayeeAmount &&
          record.PayeeAmount.toString().includes(lowerCaseSearchTerm)) ||
        (record.date && record.date.includes(lowerCaseSearchTerm)) ||
        (record.PaymentDescription &&
          record.PaymentDescription.includes(lowerCaseSearchTerm)) ||
        (record.payeeName &&
          record.payeeName.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (record.owner &&
          record.owner.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (record.type && record.type.toLowerCase().includes(lowerCaseSearchTerm))
      );
    });
    setSearchResults(filteredRecords);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setSearchResults([]);
  };

  useEffect(() => {
    // Fetch department data from Firestore
    const fetchDepartment = async () => {
      const departmentRef = doc(db, "BCS", department_id);
      const departmentSnapshot = await getDoc(departmentRef);
      if (departmentSnapshot.exists()) {
        setDepartmentData(departmentSnapshot.data());
      }
    };
    fetchDepartment();
  }, [department_id]);

  useEffect(() => {
    const initialBalance = records.reduce((acc, record) => {
      if (record.type === "income") {
        return acc + parseFloat(record.price);
      } else if (record.type === "Withdrawal") {
        return acc - parseFloat(record.price);
      }
      return acc;
    }, 0);

    setCurrentBalance(initialBalance);
  }, [records]);

  const handlePrint = () => {
    const printWindow = window.open("", "", "width=800,height=600");

    printWindow.document.write("<html><head><title>BCS HR</title>");
    printWindow.document.write("<style>");
    printWindow.document.write(
      "body { font-family: Arial, sans-serif; padding: 20px; }"
    );
    printWindow.document.write("h1 { font-size: 24px; margin-bottom: 20px; }");
    printWindow.document.write(
      "table { width: 100%; border-collapse: collapse; }"
    );
    printWindow.document.write(
      "th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }"
    );
    printWindow.document.write("th { background-color: #f2f2f2; }");
    printWindow.document.write(".text-red { color: red; }");
    printWindow.document.write(".text-green { color: green; }");
    printWindow.document.write("</style>");
    printWindow.document.write("</head><body>");
    printWindow.document.write(
      "<h1>" + departmentData.department_name + " Records</h1>"
    );
    // printWindow.document.write(
    //   "<p>Profitability: " + departmentData.profitability + "</p>"
    // );
    if (searchResults.length > 0) {
      // If there are search results, calculate total income and total expenditure from the search results
      const totalIncome = searchResults
        .filter((record) => record.type === "Income")
        .reduce((total, record) => total + parseFloat(record.price), 0);

      const totalExpenditure = searchResults
        .filter((record) => record.type === "Expenditure")
        .reduce((total, record) => total + parseFloat(record.price), 0);

      printWindow.document.write("<p>Total Income: ₦" + totalIncome + "</p>");
      printWindow.document.write(
        "<p>Total Expenditure: ₦" + totalExpenditure + "</p>"
      );

      // Calculate and display the grand total based on search results
      const grandTotal = currentBalance + totalIncome - totalExpenditure;
      printWindow.document.write("<p>Grand Total: ₦" + grandTotal + "</p>");
    } else {
      // If there are no search results, calcu
    }

    // Generate the table HTML
    printWindow.document.write("<table>");
    printWindow.document.write("<thead>");
    printWindow.document.write("<tr>");
    printWindow.document.write("<th>Type</th>");
    printWindow.document.write("<th>Amount</th>");
    printWindow.document.write("<th>Paid By</th>");
    printWindow.document.write("<th>Purpose</th>");
    printWindow.document.write("<th>Date</th>");
    printWindow.document.write("</tr>");
    printWindow.document.write("</thead>");
    printWindow.document.write("<tbody>");

    // Generate table rows from searchResults or filteredRecords based on whether there are search results
    (searchResults.length > 0 ? searchResults : filteredRecords).forEach(
      (record) => {
        const typeClass =
          record.type === "Expenditure" ? "text-red" : "text-green";
        printWindow.document.write("<tr>");
        printWindow.document.write(
          '<td class="' + typeClass + '">' + record.type + "</td>"
        );
        printWindow.document.write("<td>₦" + record.PayeeAmount + "</td>");
        printWindow.document.write("<td>" + record.payeeName + "</td>");
        printWindow.document.write(
          "<td>" + record.PaymentDescription + "</td>"
        );
        printWindow.document.write("<td>" + record.createdAt + "</td>");
        printWindow.document.write("</tr>");
      }
    );

    // Close the table and body
    printWindow.document.write("</tbody>");
    printWindow.document.write("</table>");
    printWindow.document.write("</body></html>");

    // Trigger the print dialog
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const openPopup = () => {
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  const openPop = () => {
    setPopVisible(true);
  };
  const openPops = () => {
    setPopsVisible(true);
  };

  const closePops = () => {
    setPopsVisible(false);
  };
  const closePop = () => {
    setPopVisible(false);
  };

  const openPopup1 = () => {
    setPopupVisible1(true);
  };

  const closePopup1 = () => {
    setPopupVisible1(false);
  };
  // Filter out the "Balance" records before rendering the table
  const filteredRecords = records.filter((record) => record.type !== "Balance");
  // console.log(records)

  const formatTimestamp = (timestamp) => {
    if (timestamp) {
      const date = timestamp.toDate(); // Convert to JavaScript Date
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    } else {
      return "Invalid Timestamp";
    }
  };

  useEffect(() => {
    // Create a query for the "Transactions" collection
    const db = firebase.firestore();
    const transactionRef = db
      .collection("Transactions")
      .orderBy("createdAt", "desc");
    transactionRef
      .get()
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          const transactionData = doc.data();
          if (transactionData.bcsid === department_id) {
            data.push({
              id: doc.id,
              ...transactionData,
              createdAt: formatDate(transactionData.createdAt),
            });
          }
        });
        setRecords(data);
        // setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [department_id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString.seconds * 1000); // Convert Firestore timestamp to JavaScript Date object
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const handleWithdrawal = async (values) => {
    const { withdrawnBy, phoneNumber, amount, purpose, from } = values;
    const payid = generateRandomString(10);

    const withdrawalAmount = parseFloat(amount);

    if (withdrawalAmount <= 0 || isNaN(withdrawalAmount)) {
      alert("Invalid withdrawal amount");
      return;
    }

    let updatedGeneralBalance = departmentData.balance;
    let updatedDepartmentBalance = departmentData[from]; // Get the balance of the selected department

    if (withdrawalAmount > updatedDepartmentBalance) {
      alert(`Insufficient balance in ${from} department!`);
      return;
    }

    // Deduct from chosen department balance
    updatedDepartmentBalance -= withdrawalAmount;

    // Deduct from general balance as well
    if (withdrawalAmount > departmentData.balance) {
      alert("Insufficient balance in general balance!");
      return;
    }
    updatedGeneralBalance -= withdrawalAmount;

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();

    const withdrawalRecord = {
      type: "Withdrawal",
      bcsid: department_id,
      PayeeNames: withdrawnBy,
      PayeeAmount: withdrawalAmount,
      createdAt: currentDate,
      PaymentDescription: purpose,
      payeeName: departmentData.department_name,
      payeePhoneNumber: phoneNumber,
      transactionId: payid,
      status: "Successful",
    };

    try {
      const docRef = await addDoc(
        collection(db, "Transactions"),
        withdrawalRecord
      );
      toast.success("Transaction successful");

      // Update the general balance and selected department balance in Firestore
      await updateDoc(doc(db, "BCS", department_id), {
        balance: updatedGeneralBalance,
        [from]: updatedDepartmentBalance, // Update the specific department's balance
      });
    } catch (error) {
      console.error("Transaction failed: ", error);
    }

    // Close the withdrawal form
    setPopVisible(false);
  };

  const Modal = () => (
    <AntModal
      title="Record Details"
      visible={isPopupVisible}
      onCancel={closePopup}
      footer={null}
    >
      {/* Render the detailed information of the selected record in the modal */}
      {selectedRecord && (
        <div className="space-y-2">
          <p className="font-bold text-blue-500">Type: {selectedRecord.type}</p>
          <p className="font-bold text-green-500">
            Date: {selectedRecord.createdAt}
          </p>
          <p className="font-bold text-purple-500">
            Payee Name: {selectedRecord.payeeName}
          </p>
          <p className="font-bold text-indigo-500">
            Purpose: {selectedRecord.PaymentDescription}
          </p>
          {/* Add more details as needed */}
        </div>
      )}
    </AntModal>
  );

  const handleReconciliation = async () => {
    // Ensure a record is selected
    if (!selectedRecord) {
      // Handle error, return or display a message
      return;
    }

    try {
      // Update the payeepurpose with the selected owner
      const docRef = doc(db, "Transactions", selectedRecord.id);
      await updateDoc(docRef, {
        PaymentDescription: selectedOwner,
      });

      // Get the previous owner of this transaction
      const previousOwner = selectedRecord.PaymentDescription;

      // Get the amount of this transaction
      const amount = selectedRecord.PayeeAmount;

      // Check if this transaction has been previously reconciled
      if (previousOwner) {
        // Get the BCS document for the previous owner
        const previousOwnerDocRef = doc(db, "BCS", selectedRecord.bcsid);
        const previousOwnerDocSnapshot = await getDoc(previousOwnerDocRef);

        if (previousOwnerDocSnapshot.exists()) {
          // Retrieve the BCS data for the previous owner
          const previousOwnerData = previousOwnerDocSnapshot.data();

          // Retrieve the balance for the previous owner
          const previousOwnerBalance = previousOwnerData[previousOwner] || 0;

          // Subtract the amount from the previous owner's balance
          const updatedPreviousOwnerBalance = previousOwnerBalance - amount;

          // Update the BCS document with the updated balance for the previous owner
          await updateDoc(previousOwnerDocRef, {
            [previousOwner]: updatedPreviousOwnerBalance,
          });
        }
      }

      // Get the BCS document for the newly selected owner
      const newOwnerDocRef = doc(db, "BCS", selectedRecord.bcsid);
      const newOwnerDocSnapshot = await getDoc(newOwnerDocRef);

      if (newOwnerDocSnapshot.exists()) {
        // Retrieve the BCS data for the newly selected owner
        const newOwnerData = newOwnerDocSnapshot.data();

        // Retrieve the balance for the newly selected owner
        const newOwnerBalance = newOwnerData[selectedOwner] || 0;

        // Calculate the updated balance by adding the amount to the current balance
        const updatedNewOwnerBalance = newOwnerBalance + amount;

        // Update the BCS document with the updated balance for the newly selected owner
        await updateDoc(newOwnerDocRef, {
          [selectedOwner]: updatedNewOwnerBalance,
        });

        // Display success message or perform any other action
        toast.success("Reconciliation successful");
        setPopupVisible1(false);
      } else {
        console.error("BCS document does not exist");
        // Handle error, display error message or perform other actions
      }
    } catch (error) {
      console.error("Error reconciling record: ", error);
      // Handle error, display error message or perform other actions
    }
  };

  const Modal1 = () => (
    <AntModal
      title="Reconciliation Tab"
      open={isPopupVisible1}
      onCancel={closePopup1}
      onOk={handleReconciliation}
      okButtonProps={{
        style: {background: '#1890ff'}
      }}
      // footer={null}
    >
      {/* Render the detailed information of the selected record in the modal */}
      {selectedRecord && (
        <div className="space-y-2 ">
          <p className="font-bold text-blue-500">
            Amount: {selectedRecord.PayeeAmount}
          </p>
          <p className="font-bold text-purple-500">
            Payee Name: {selectedRecord.payeeName}
          </p>
          <p className="font-bold text-indigo-500">
            Purpose: {selectedRecord.payId}
          </p>
          <Select
            value={selectedOwner}
            onChange={(value) => setSelectedOwner(value)}
            className="ml-2 border "
            style={{ width: 400 }}
            placeholder="Reconcile To"
            showSearch // Enable search
            optionFilterProp="children" // Specify which property of Option is used for filtering
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            required
          >
            <Option value="General">General Fellowship </Option>
            <Option value="Women">Women Fellowship</Option>
            <Option value="Men">Men Fellowship</Option>
            <Option value="Sabbath"> Sabbath Children Fellowship</Option>
            <Option value="Choristers">Choristers</Option>
            <Option value="Elders">Elders</Option>
            <Option value="Ordain">All Ordain Ones</Option>
            <Option value="Spirited">Spirited Fellowship</Option>
            <Option value="Youth">Youth Fellowship</Option>
            <Option value="New">New World</Option>
            <Option value="Brothers/Sisters">Brothers And Sisters</Option>
            <Option value="Cuscc">Spiritual Counsel Of Chuches</Option>
            <Option value="Abas">Abas</Option>
            <Option value="Education">Education Fellowship</Option>
            <Option value="Health">Health Workers Fellowship</Option>
            <Option value="Labour">Labour Fellowship</Option>
            <Option value="Lawyers">Lawyers Fellowship</Option>
            <Option value="Vegetarian">Vegetarian Fellowship</Option>
            <Option value="Welfare">Welfare Fellowship</Option>
            <Option value="Traditional">Traditional Rulers Fellowship</Option>
            <Option value="144">144s</Option>
            <Option value="DV">DVs</Option>
            <Option value="TCW">TCW</Option>
            <Option value="CNP">CNP</Option>
            <Option value="CPS">CPS</Option>
            <Option value="SCS">SCS</Option>
            <Option value="COB">College Of Bishops</Option>
            <Option value="Christ Amb">Christ Ambassadors</Option>
            <Option value="MOB">Mansion Of Blessedness</Option>
            <Option value="CSherpherds">Sherpherds</Option>
            <Option value="Priest">Priest Welfare</Option>
            <Option value="Project">Project</Option>
            <Option value="PreService">Pre Service</Option>
            {/* Add more options as needed */}
          </Select>

          {/* Add more details as needed */}
          {/* <Button
    onClick={handleReconciliation}
    className="bg-blue-500 m-10 text-white"
  >
    Confirm
  </Button> */}
        </div>
      )}
    </AntModal>
  );

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => (
        <span
          className={`font-bold text-lg ${
            record.type === "Withdrawal"
              ? "text-red-500"
              : record.type === "Transfer"
              ? "text-red-300"
              : "text-green-500"
          }`}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "PayeeAmount",
      key: "PayeeAmount",
      render: (text, record) => (
        <span
          className={`font-bold text-lg ${
            record.type === "Withdrawal"
              ? "text-red-500"
              : record.type === "Transfer"
              ? "text-red-300"
              : "text-green-500"
          }`}
        >
          ₦ {text?.toLocaleString("en-US") || "0"}
        </span>
      ),
    },
    {
      title: "Purpose",
      dataIndex: "PaymentDescription",
      key: "PaymentDescription",
      render: (text) => <span className="font-bold text-lg">{text}</span>,
    },
    {
      title: "Name",
      dataIndex: "payeeName",
      key: "payeeName",
      render: (text) => <span className="font-bold text-lg">{text}</span>,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span className="font-bold text-lg">{text}</span>,
    },
    
    {
      title: "Reconciliation",
      dataIndex: "PaymentDescription",
      key: "PaymentDescription",
      render: (text, record) => (
        <span
          className="text-blue-500 cursor-pointer"
          onClick={() => {
            setSelectedRecord(record);
            openPopup1();
          }}
        >
          Reconcile
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (text, record) => (
        <span
          className="text-blue-500 cursor-pointer"
          onClick={() => {
            setSelectedRecord(record);
            openPopup();
          }}
        >
          Details
        </span>
      ),
    },
  ].filter(Boolean);

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    }
  };

  async function updateTotalBalance() {
    try {
      // Fetch documents from the BCS collection
      const bcsCollectionRef = collection(db, "BCS");
      const bcsDocsSnapshot = await getDocs(bcsCollectionRef);
  
      // Iterate over each document in the BCS collection
      for (const bcsDoc of bcsDocsSnapshot.docs) {
        const bcsid = bcsDoc.id;
  
        // Query the transaction collection for transactions with the same bcsid
        const transactionQuery = query(
          collection(db, "Transactions"),
          where("bcsid", "==", bcsid)
        );
        const transactionDocsSnapshot = await getDocs(transactionQuery);
  
        // Calculate total payeeAmount and total deduction amount
        let totalPayeeAmount = 0;
        let totalDeduction = transactionDocsSnapshot.size * 100; // Calculate deduction outside the loop
  
        transactionDocsSnapshot.forEach((transactionDoc) => {
          const transactionData = transactionDoc.data();
          totalPayeeAmount += transactionData.PayeeAmount;
  
          // console.log(transactionData.PayeeAmount);
          // console.log(totalPayeeAmount);
        });
  
        // Update the totalBalance and balance fields in the BCS document
        const balancePayeeAmount = totalPayeeAmount - totalDeduction;
        console.log(balancePayeeAmount);

        await setDoc(
          bcsDoc.ref,
          { totalBalance: totalPayeeAmount, balance: balancePayeeAmount },
          { merge: true }
        );
      }
  
      console.log("Total balance updated successfully for all BCS documents.");
    } catch (error) {
      console.error("Error updating total balance:", error);
    }
  }
  
 

  const filterRecordsByDateRange = () => {
    if (startDate && endDate) {
      const filteredRecords = records.filter((record) => {
        const recordDate = new Date(record.createdAt);
        return recordDate >= startDate && recordDate <= endDate;
      });
      setSearchResults(filteredRecords);
    }
  };

  useEffect(() => {
    // Fetch the list of departments from Firestore
    const fetchDepartments = async () => {
      const querySnapshot = await getDocs(collection(db, "BCS"));
      const departmentData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDepartments(departmentData);
    };

    fetchDepartments();
  }, []);

  const handleTransfer = async (values) => {
    const { amount, purpose } = values;
    const payid = generateRandomString(10);
    const transferAmount = parseFloat(amount);

    if (transferAmount <= 0 || isNaN(transferAmount)) {
      alert("Invalid transfer amount");
    } else if (transferAmount > departmentData.balance) {
      alert("Insufficient balance!");
    } else {
      // Retrieve the ID of the selected department
      const selectedDepartmentId = departments.find(
        (department) => department.department_name === selectedDepartment
      ).id;

      // Get the document reference for the selected department
      const selectedDepartmentRef = doc(db, "BCS", selectedDepartmentId);

      // Retrieve the selected department's data
      const selectedDepartmentSnapshot = await getDoc(selectedDepartmentRef);
      const selectedDepartmentData = selectedDepartmentSnapshot.data();

      // Update the balance of the selected department by adding the transferred amount
      const updatedSelectedDepartmentBalance =
        selectedDepartmentData.balance + transferAmount;
      const updatedSelectedDepartmentTotalBalance =
        selectedDepartmentData.totalBalance + transferAmount;

      // Update the selected department's balance in Firestore
      await updateDoc(selectedDepartmentRef, {
        balance: updatedSelectedDepartmentBalance,
        totalBalance: updatedSelectedDepartmentTotalBalance,
      });

      // Update the local state with the new balance of the selected department
      setDepartments((prevState) =>
        prevState.map((department) =>
          department.id === selectedDepartmentId
            ? { ...department, balance: updatedSelectedDepartmentBalance, totalBalance: updatedSelectedDepartmentTotalBalance }
            : department
        )
      );

      // Create transfer records for both departments
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString();

      // Record for the transferring department
      const transferRecordFrom = {
        type: "Transfer",
        bcsid: department_id,
        PayeeAmount: - transferAmount, // Negative amount for the transferring department
        createdAt: currentDate,
        destination: selectedDepartment,
        PaymentDescription: purpose,
        payeeName: selectedDepartmentData.department_name,
        PayeePurpose: departmentData.department_name,
        transactionId: payid,
        status: "Successful",
      };

      // Record for the receiving department
      const transferRecordTo = {
        type: "income",
        bcsid: selectedDepartmentId,
        PayeeAmount: transferAmount, // Positive amount for the receiving department
        createdAt: currentDate,
        source: departmentData.department_name,
        PaymentDescription: purpose,
        payeeName: departmentData.department_name,
        PayeePurpose: selectedDepartmentData.department_name,
        transactionId: payid,
        status: "Successful",
      };

      try {
        // Add a transaction record for the transferring department
        await addDoc(collection(db, "Transactions"), transferRecordFrom);

        // Add a transaction record for the receiving department
        await addDoc(collection(db, "Transactions"), transferRecordTo);

        // Update the department's balance in Firestore
        await updateDoc(doc(db, "BCS", department_id), {
          balance: departmentData.balance - transferAmount,
          totalBalance: departmentData.totalBalance - transferAmount,
        });

        // Update the local state with the new balance
        setDepartmentData((prevState) => ({
          ...prevState,
          balance: prevState.balance - transferAmount,
        }));

        toast.success("Transfer successful");
      } catch (error) {
        console.error("Transfer failed: ", error);
        toast.error("Transfer failed");
      }

      // Close the transfer form
      setPopsVisible(false);
    }
  };

  return (
    <div className="flex flex-col md:ml-44">
      {departmentData ? (
        <div className="lg:w-1/2">
          <h1 className="text-xl lg:text-2xl font-bold mb-4">
            Account Name: {departmentData.account_name}
          </h1>
          <h1 className="text-xl lg:text-2xl font-bold mb-4">
            Account Number: {departmentData.account_number}
          </h1>
          <h1 className="text-xl lg:text-2xl font-bold mb-4">
            {"Bank: 9 Payment Service Bank"}
          </h1>
          {[
            "bcsict@ooobcs.org",
            "bcstreasury@ooobcs.org",
            "hr@ooobcs.org",
          ].includes(firebase.auth().currentUser.email) && (
            <>
              <div className="grid grid-cols-1 lg:row lg:flex gap-4 mb-5">
                <div className="shadow-lg p-5 h-44 shadow-blue-200 bg-[#3ec9d6] rounded-md text-center">
                  <FaBitcoin
                    size={50}
                    color="#3ec9d6"
                    className="self-center mb-4"
                  />
                  <h2 className="font-extrabold text-5xl -mt-5 text-white">
                    Balance
                  </h2>
                  <h2 className="font-extrabold text-2xl text-white">
                    ₦
                    {departmentData.balance
                      ? departmentData.balance.toLocaleString("en-US")
                      : "0"}
                  </h2>
                </div>

                {/* <div onClick={() => updateTotalBalance()} className="cursor-pointer"> */}
                <div onClick={() => openPop()} className="cursor-pointer">
                  <div className="shadow-lg p-5 h-44 shadow-blue-200 bg-[#ffa21d] rounded-md text-center">
                    <BiMoneyWithdraw
                      size={50}
                      color="#ffa21d"
                      className="self-center mb-4"
                    />
                    <h2 className="font-extrabold text-4xl -mt-3 text-white">
                      Withdraw
                    </h2>
                    <h2 className="font-extrabold text-2xl text-white"> </h2>
                  </div>
                </div>
                <div onClick={() => openPops()} className="cursor-pointer">
                  <div className="shadow-lg p-5 h-44 shadow-blue-200 bg-[#ffa21d] rounded-md text-center">
                    <BiMoneyWithdraw
                      size={50}
                      color="#ffa21d"
                      className="self-center mb-4"
                    />
                    <h2 className="font-extrabold text-4xl -mt-3 text-white">
                      Transfer
                    </h2>
                    <h2 className="font-extrabold text-2xl text-white"> </h2>
                  </div>
                </div>
              </div>
             
<h2 className="font-extrabold text-2xl -mt-3">
                      {records.length.toLocaleString()} Transsactions
                    </h2>
            </>
          )}


          {
            department_id === "wXzzgy1IQIWGvYIJzsEx" ? null :
                          <div className="shadow-lg p-5 h-44 mb-5 shadow-blue-200 bg-[#3ec9d6] rounded-md text-center">
                  <FaBitcoin
                    size={50}
                    color="#3ec9d6"
                    className="self-center mb-4"
                  />
                  <h2 className="font-extrabold text-5xl -mt-5 text-white">
                  Total Balance
                  </h2>
                  <h2 className="font-extrabold text-2xl text-white">
                    ₦
                    { departmentData?.totalBalance?.toLocaleString("en-US") }
                  </h2>
                </div>
          }


                <Select
                onChange={(value) => setSelectedDepartmentfrom(value)}
                value={selectedDepartmentfrom}
                placeholder="Balances "
                className="w-full text-red-800 mb-6"
              >
                <Option value="General">
                  General Fellowship Balance = ₦: {departmentData.General || 0}
                </Option>
                <Option value="Women">
                  Women Fellowship Balance = ₦: {departmentData.Women || 0}
                </Option>
                <Option value="Men">
                  Men Fellowship Balance = ₦: {departmentData.Men || 0}
                </Option>
                <Option value="Sabbath">
                  {" "}
                  Sabbath Children Fellowship Balance = ₦:{" "}
                  {departmentData.Sabbath || 0}
                </Option>
                <Option value="Choristers">
                  Choristers Balance = ₦: {departmentData.Choristers || 0}
                </Option>
                <Option value="Elders">
                  Elders Balance = ₦: {departmentData.Elders || 0}
                </Option>
                <Option value="Ordain">
                  All Ordain Ones Balance = ₦: {departmentData.Ordain || 0}
                </Option>
                <Option value="Spirited">
                  Spirited Fellowship Balance = ₦:{" "}
                  {departmentData.Spirited || 0}
                </Option>
                <Option value="Youth">
                  Youth Fellowship Balance = ₦: {departmentData.Youth || 0}
                </Option>
                <Option value="New">
                  New World Balance = ₦: {departmentData.New || 0}
                </Option>
                <Option value="BBSF">
                  Brothers And Sisters Balance = ₦: {departmentData.BBSF || 0}
                </Option>
                <Option value="Cuscc">
                  Spiritual Counsel Of Chuches Balance = ₦:{" "}
                  {departmentData.Cuscc || 0}
                </Option>
                <Option value="Abas">
                  Abas Balance = ₦: {departmentData.Abas || 0}
                </Option>
                <Option value="Education">
                  Education Fellowship Balance = ₦:{" "}
                  {departmentData.Education || 0}
                </Option>
                <Option value="Health">
                  Health Workers Fellowship Balance = ₦:{" "}
                  {departmentData.Health || 0}
                </Option>
                <Option value="Labour">
                  Labour Fellowship Balance = ₦: {departmentData.Labour || 0}
                </Option>
                <Option value="Lawyers">
                  Lawyers Fellowship Balance = ₦: {departmentData.Lawyers || 0}
                </Option>
                <Option value="Vegetarian">
                  Vegetarian Fellowship Balance = ₦:{" "}
                  {departmentData.Vegetarian || 0}
                </Option>
                <Option value="Welfare">
                  Welfare Fellowship Balance = ₦: {departmentData.Welfare || 0}
                </Option>
                <Option value="Traditional">
                  Traditional Rulers Fellowship Balance = ₦:{" "}
                  {departmentData.Traditional || 0}
                </Option>
                <Option value="Virgins">
                  144,000 Virgins Balance = ₦: {departmentData.Virgins || 0}
                </Option>
                <Option value="Dv">
                  DVs Balance = ₦: {departmentData.Dv || 0}
                </Option>
                <Option value="Tcw">
                  TCW Balance = ₦: {departmentData.Tcw || 0}
                </Option>
                <Option value="Cnp">
                  CNP Balance = ₦: {departmentData.Cnp || 0}
                </Option>
                <Option value="Cps">
                  CPS Balance = ₦: {departmentData.Cps || 0}
                </Option>
                <Option value="Scs">
                  SCS Balance = ₦: {departmentData.Scs || 0}
                </Option>
                <Option value="Cob">
                  College Of Bishops Balance = ₦: {departmentData.Cob || 0}
                </Option>
                <Option value="Christ">
                  Christ Ambassadors Balance = ₦: {departmentData.Christ || 0}
                </Option>
                <Option value="Mob">
                  Mansion Of Blessedness Balance = ₦: {departmentData.Mob || 0}
                </Option>
                <Option value="Sheps">
                  Sherpherds Balance = ₦: {departmentData.Sheps || 0}
                </Option>
                <Option value="Priest">
                  Priest Welfare Balance = ₦: {departmentData.Priest || 0}
                </Option>
                <Option value="Project">
                   Project Balance = ₦: {departmentData.Project || 0}
                </Option>
                <Option value="Preservice">
                   Pre-Service Balance = ₦: {departmentData.PreService || 0}
                </Option>
                {/* Add more options as needed */}
              </Select>
          {/* ... (remaining code) */}
        </div>
      ) : (
        <p className="text-lg font-semibold">Department not found</p>
      )}
      <div className="mt-4 lg:mt-0 flex flex-col lg:flex-row items-center lg:items-start">
        <Search
          placeholder="Search by Name, Amount, Date, or Purpose"
          onSearch={handleSearch}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          style={{ width: "100%", maxWidth: "300px", marginBottom: "1rem" }}
          className="bg-blue-400 rounded-xl"
          enterButton
        />

        <DatePicker.RangePicker
          onChange={handleDateRangeChange}
          className="ml-3"
          // style={{ marginRight: "10px" }}
        />
        <div className="flex row bg-emerald-500 ml-4 rounded-xl ">
          <Button type="primary" onClick={filterRecordsByDateRange}>
            Filter
            {/* <IoMdFunnel className="" color="#fff"/> */}
          </Button>
        </div>

        {/* Buttons Grid */}
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:flex flex-col lg:flex-row gap-4 items-center lg:items-start lg:ml-10">
          <Button
            type="primary"
            onClick={handlePrint}
            icon={<PrinterOutlined />}
            className="bg-blue-500 w-full lg:w-32 mb-2 lg:mb-0"
          >
            Print
          </Button>
          <Link to="/requisition">
            <Button
              type="primary"
              icon={<SiLibrariesdotio />}
              className="bg-blue-500 w-full lg:w-32 mb-2 lg:mb-0"
            >
              Requisition
            </Button>
          </Link>
          <Link to="/retirements">
            <Button
              type="primary"
              icon={<IoLibrarySharp />}
              className="bg-red-400 w-full lg:w-32 mb-2 lg:mb-0"
            >
              Retirement
            </Button>
          </Link>
          <Link to="/">
            <Button
              type="primary"
              icon={<CgLogIn />}
              className="bg-red-400 w-full lg:w-32 mb-2 lg:mb-0 lg:hidden"
              onClick={() => {
                auth
                  .signOut()
                  .then((res) => toast.success("You have been logged out!"))
                  .catch((err) => {
                    toast.error("An Error Occurred!");
                  });
              }}
            >
              LogOut
            </Button>
          </Link>
          {/* ... (other buttons) */}
        </div>
        {/* Search Component */}
      </div>
      <div className="mt-8 ">
        <h2 className="text-xl font-semibold mb-2">Records</h2>
        <Table
          columns={columns}
          dataSource={searchResults.length > 0 ? searchResults : records}
          pagination={{
            pageSize: 10,
          }}
          scroll={{ x: "max-content" }}
          className="md:w-[95%] p-1 cursor-pointer w-[200%]"
        />
      </div>
      {/* Render the modal */}
      <Modal />

      <Modal1 />

      <AntModal
        title="Withdrawal Form"
        visible={isPopVisible}
        onCancel={() => setPopVisible(false)}
        footer={null} // Remove the footer to use custom submit logic
      >
        <Form onFinish={handleWithdrawal}>
          <Form.Item
            label="Withdrawn By"
            name="withdrawnBy"
            rules={[{ required: true, message: "Please enter withdrawn by" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[{ required: true, message: "Please enter phone number" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Amount"
            name="amount"
            rules={[{ required: true, message: "Please enter amount" }]}
          >
            <Input type="number" />
          </Form.Item>
          {/* {departmentData.category === "Bethel" && ( */}
            <Form.Item
              label="Withdraw From"
              name="from"
              rules={[{ message: "Please Select" }]}
            >
              <Select
                onChange={(value) => setSelectedDepartmentfrom(value)}
                value={selectedDepartmentfrom}
                placeholder="Select"
                className="w-full text-red-800"
                // showSearch // Enable search
                // optionFilterProp="children" // Specify which property of Option is used for filtering
                // filterOption={(input, option) =>
                //   option.children
                //     .toLowerCase()
                //     .indexOf(input.toLowerCase()) >= 0
                // } // Custom filter function
                // required
              >
                <Option value="bals">
                  Main Balance = ₦: {departmentData.balance || 0}
                </Option>
                <Option value="General">
                  General Fellowship Balance = ₦: {departmentData.General || 0}
                </Option>
                <Option value="Women">
                  Women Fellowship Balance = ₦: {departmentData.Women || 0}
                </Option>
                <Option value="Men">
                  Men Fellowship Balance = ₦: {departmentData.Men || 0}
                </Option>
                <Option value="Sabbath">
                  {" "}
                  Sabbath Children Fellowship Balance = ₦:{" "}
                  {departmentData.Sabbath || 0}
                </Option>
                <Option value="Choristers">
                  Choristers Balance = ₦: {departmentData.Choristers || 0}
                </Option>
                <Option value="Elders">
                  Elders Balance = ₦: {departmentData.Elders || 0}
                </Option>
                <Option value="Ordain">
                  All Ordain Ones Balance = ₦: {departmentData.Ordain || 0}
                </Option>
                <Option value="Spirited">
                  Spirited Fellowship Balance = ₦:{" "}
                  {departmentData.Spirited || 0}
                </Option>
                <Option value="Youth">
                  Youth Fellowship Balance = ₦: {departmentData.Youth || 0}
                </Option>
                <Option value="New">
                  New World Balance = ₦: {departmentData.New || 0}
                </Option>
                <Option value="BBSF">
                  Brothers And Sisters Balance = ₦: {departmentData.BBSF || 0}
                </Option>
                <Option value="Cuscc">
                  Spiritual Counsel Of Chuches Balance = ₦:{" "}
                  {departmentData.Cuscc || 0}
                </Option>
                <Option value="Abas">
                  Abas Balance = ₦: {departmentData.Abas || 0}
                </Option>
                <Option value="Education">
                  Education Fellowship Balance = ₦:{" "}
                  {departmentData.Education || 0}
                </Option>
                <Option value="Health">
                  Health Workers Fellowship Balance = ₦:{" "}
                  {departmentData.Health || 0}
                </Option>
                <Option value="Labour">
                  Labour Fellowship Balance = ₦: {departmentData.Labour || 0}
                </Option>
                <Option value="Lawyers">
                  Lawyers Fellowship Balance = ₦: {departmentData.Lawyers || 0}
                </Option>
                <Option value="Vegetarian">
                  Vegetarian Fellowship Balance = ₦:{" "}
                  {departmentData.Vegetarian || 0}
                </Option>
                <Option value="Welfare">
                  Welfare Fellowship Balance = ₦: {departmentData.Welfare || 0}
                </Option>
                <Option value="Traditional">
                  Traditional Rulers Fellowship Balance = ₦:{" "}
                  {departmentData.Traditional || 0}
                </Option>
                <Option value="Virgins">
                  144,000 Virgins Balance = ₦: {departmentData.Virgins || 0}
                </Option>
                <Option value="Dv">
                  DVs Balance = ₦: {departmentData.Dv || 0}
                </Option>
                <Option value="Tcw">
                  TCW Balance = ₦: {departmentData.Tcw || 0}
                </Option>
                <Option value="Cnp">
                  CNP Balance = ₦: {departmentData.Cnp || 0}
                </Option>
                <Option value="Cps">
                  CPS Balance = ₦: {departmentData.Cps || 0}
                </Option>
                <Option value="Scs">
                  SCS Balance = ₦: {departmentData.Scs || 0}
                </Option>
                <Option value="Cob">
                  College Of Bishops Balance = ₦: {departmentData.Cob || 0}
                </Option>
                <Option value="Christ">
                  Christ Ambassadors Balance = ₦: {departmentData.Christ || 0}
                </Option>
                <Option value="Mob">
                  Mansion Of Blessedness Balance = ₦: {departmentData.Mob || 0}
                </Option>
                <Option value="Sheps">
                  Sherpherds Balance = ₦: {departmentData.Sheps || 0}
                </Option>
                <Option value="Priest">
                  Priest Welfare Balance = ₦: {departmentData.Priest || 0}
                </Option>
                <Option value="Project">
                   Project Balance = ₦: {departmentData.Project || 0}
                </Option>
                <Option value="Preservice">
                   Pre-Service Balance = ₦: {departmentData.PreService || 0}
                </Option>
              </Select>
            </Form.Item>
          {/* )} */}
          <Form.Item
            label="Purpose"
            name="purpose"
            rules={[{ required: true, message: "Please enter purpose" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item className=" ml-48">
            <Button
              type="primary"
              htmlType="submit"
              className="bg-blue-700 font-bold"
            >
              Withdraw
            </Button>
          </Form.Item>
        </Form>
      </AntModal>

      <AntModal
        title="Transfer Form"
        visible={isPopsVisible}
        onCancel={() => setPopsVisible(false)}
        footer={null} // Remove the footer to use custom submit logic
      >
        <Form onFinish={handleTransfer}>
          <Form.Item
            label="Transfer to"
            name="transferto"
            rules={[{ required: true, message: "Please enter withdrawn by" }]}
          >
            <Select
              showSearch // Enable search
              optionFilterProp="children" // Specify which property of Option is used for filtering
              onChange={(value) => setSelectedDepartment(value)}
              value={selectedDepartment}
              placeholder="Select Department"
              className="w-full text-red-800"
              filterOption={(input, option) =>
                option.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              } // Custom filter function
              required
            >
              {departments.map((department) => (
                <Option key={department?.id} value={department?.department_name}>
                  {department?.department_name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Amount"
            name="amount"
            rules={[{ required: true, message: "Please enter amount" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Purpose"
            name="purpose"
            rules={[{ required: true, message: "Please enter purpose" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" className="bg-blue-600" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </AntModal>
    </div>
  );
};
export default DepartmentScreen;
