import { createUserWithEmailAndPassword } from "firebase/auth";
import { addDoc, collection, getDocs } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { auth, db } from "../firebase";
import { useUser } from "../components/user-info/UserContext";
import { Select } from "antd";

const { Option } = Select;

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [departments, setDepartments] = useState([]);
  const { setUserDepartment } = useUser();

  useEffect(() => {
    // Fetch the list of departments from Firestore
    const fetchDepartments = async () => {
      const querySnapshot = await getDocs(collection(db, "BCS"));
      const departmentData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDepartments(departmentData);
    };

    fetchDepartments();
  }, []);

  // Update the email field when the department is selected
  useEffect(() => {
    const selectedDepartmentData = departments.find(
      (department) => department.department_name === selectedDepartment
    );
    if (selectedDepartmentData) {
      setEmail(`${selectedDepartmentData.departmental_email}`);
    }
  }, [selectedDepartment, departments, email]);

  async function signUp() {
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      // Find the department ID associated with the selected department name
      const selectedDepartmentData = departments.find(
        (department) => department.department_name === selectedDepartment
      );

      if (!selectedDepartmentData) {
        toast.error("Selected department not found");
        return;
      }

      // Create the user account using Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Save user details to Firestore in the 'DepartUsers' collection
      await addDoc(collection(db, "DepartUsers"), {
        name: name,
        email: email,
        department: selectedDepartmentData.id,
        role: selectedRole,
        uid: user.uid,
      });
      // setUserDepartment(selectedDepartmentData.id);
      toast.success("Sign up successful");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred");
    }
  }

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-blue-300 rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Create User
            </h1>
            <form className="space-y-4 md:space-y-6" action="#">
              <div>
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your Name
                </label>
                <input
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  name="name"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="John Doe"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your Email
                </label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@company.com"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  name="password"
                  id="password"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="••••••••"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="confirmPassword"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Confirm Password
                </label>
                <input
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="••••••••"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="department"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Select Department
                </label>
                <Select
                  showSearch // Enable search
                  optionFilterProp="children" // Specify which property of Option is used for filtering
                  onChange={(value) => setSelectedDepartment(value)}
                  value={selectedDepartment}
                  placeholder="Select Department"
                  className="w-full text-red-800"
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  } // Custom filter function
                  required
                >
                  <Option value="" disabled>
                    Select Department
                  </Option>
                  {departments.map((department) => (
                    <Option
                      key={department.id}
                      value={department.department_name}
                    >
                      {department.department_name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div>
                <label
                  htmlFor="role"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Select Role
                </label>
                <Select
                  onChange={(value) => setSelectedRole(value)}
                  value={selectedRole}
                  placeholder="Select Role"
                  className="w-full text-red-800"
                  showSearch // Enable search
                  optionFilterProp="children" // Specify which property of Option is used for filtering
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  } // Custom filter function
                  required
                >
                  <Option value="ict">Ict</Option>
                  <Option value="TitheOffice">TitheOffice</Option>
                  <Option value="Supervisor">Supervisor</Option>
                  <Option value="AuditUnit">AuditUnit</Option>
                  <Option value="Bethel">Bethel</Option>
                  <Option value="others">others</Option>
                  <Option value="sokezaaudit">Sokeza Audit</Option>
                  <Option value="sokeza">Sokeza </Option>
                  <Option value="Katkakadaudit">Katkakad Audit</Option>
                  <Option value="Katkakad">Katkakad </Option>
                  <Option value="jigobaaudit">Jigoba Audit</Option>
                  <Option value="jigoba">Jigoba </Option>
                  <Option value="boyoadaudit">Boyoad Audit</Option>
                  <Option value="boyoad">Boyoad </Option>
                  <Option value="Platarbenaudit">Platarben Audit</Option>
                  <Option value="Platarben">Platarben</Option>
                  <Option value="Nignasabuaudit">Nignasabu Audit</Option>
                  <Option value="Nignasabu">Nignasabu</Option>
                  <Option value="Kwoyosekaudit">Kwoyosek Audit</Option>
                  <Option value="Kwoyosek">Kwoyosek </Option>
                  <Option value="Ogonlaaudit">Ogonla Audit</Option>
                  <Option value="Ogonla">Ogonla</Option>
                  <Option value="Kodeledoaudit">Kodeledo Audit</Option>
                  <Option value="Kodeledo">Kodeledo </Option>
                  <Option value="Akribayaudit">Akribay Audit</Option>
                  <Option value="Akribay">Akribay </Option>
                  <Option value="Crebenaudit">Creben Audit</Option>
                  <Option value="Creben">Creben </Option>
                  <Option value="Abanimoaudit">Abanimo Audit</Option>
                  <Option value="Abanimo">Abanimo</Option>
                </Select>
              </div>
              <div
                onClick={signUp}
                className="border-white border-1 bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white bg-purple-400 dark:hover:bg-primary-700 dark:focus:ring-primary-800 cursor-pointer"
              >
                Sign up
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signup;
