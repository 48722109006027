import React, { useEffect, useState } from "react";
import { AiOutlineRollback } from "react-icons/ai";
import { BsArrowBarRight } from "react-icons/bs";
import { BsArrowBarDown } from "react-icons/bs";
import { Link } from "react-router-dom";
import Data from "./Data";
import firebase from "../../firebaseconfig";
// import firebase from 'firebase/compat/app';
import data2 from "./data2";
import { FaBitcoin } from "react-icons/fa";

const DepartFinance = () => {
  const [departmentData, setDepartmentData] = useState({});
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [balance, setBalance] =  useState()

  const handleSearch = () => {
    const filteredRecords = records.filter((record) => {
      return (
        record.price.includes(searchTerm) ||
        record.date.includes(searchTerm) ||
        record.from.includes(searchTerm) ||
        record.type.includes(searchTerm)
      );
    });
    setSearchResults(filteredRecords);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setSearchResults([]);
  };
  useEffect(() => {
    const db = firebase.firestore().collection('Transactions')
    // const transactionRef = db.collection("Transactions");
  
// console.log(transactionRef)

    // transactionRef
    .orderBy('createdAt', 'desc')
      .where("category", "==", "Department")
      .onSnapshot((snap)  => {
        const posts = snap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        setRecords(posts)

      })

      return db
  }, []);
  
  
//   })

  

  


useEffect(() => {
const db = firebase.firestore();
const transactionRef = db.collection("BCS");

transactionRef
 .where("category", "==", "Department") // Filter by the category "Department"
 .get()
 .then((querySnapshot) => {
   const data = [];
   let totalBalance = 0; // Initialize total balance

   querySnapshot.forEach((doc) => {
     const transactionData = doc.data();
     data.push(transactionData);

     // Check if "balance" exists and add it to the total
     if (transactionData.balance) {
       totalBalance += transactionData.balance;
     }
   });

   setBalance(totalBalance); // Set the total balance in the state
 })
 .catch((error) => {
   console.error("Error fetching data: ", error);
 });
}, []);
  
  
  const formatTimestamp = (timestamp) => {
    if (timestamp) {
      const date = timestamp.toDate(); // Convert to JavaScript Date
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    } else {
      return "Invalid Timestamp";
    }
  };
  


  return (
    <div>
      <h1 className="text-gray-500 font-bold text-lg text-center m-5">
        DEPARTMENTS/ESTABLISHMENTS
      </h1>
      <div className="flex justify-center">

        <div className="shadow-lg p-5 h-44 w-96 shadow-blue-200 bg-[#3ec9d6] rounded-md text-center">
          <FaBitcoin size={50} color="#3ec9d6" className="self-center" />
          <h2 className="font-extrabold text-5xl -mt-5 text-white m-4">
           Balance
          </h2>
          <h2 className="font-extrabold text-2xl text-white m-4">
            {/* {balance} */}
            ₦{balance ? balance.toLocaleString("en-US") : '0'}
          </h2>
        </div>
      </div>

      <div className="mt-20">
        <table className="w-full text-sm text-left dark:text-gray-400">
          <thead className="uppercase bg-gray-50 dark:bg-gray-700">
            <tr>
              <th scope="col" className="px-6 py-3">
                Type
              </th>
              <th scope="col" className="px-6 py-3">
                AMOUNT
              </th>
              <th scope="col" className="px-6 py-3">
                PURPOSE
              </th>
              <th scope="col" className="px-6 py-3">
                DEPARTMENT
              </th>
              <th scope="col" className="px-6 py-3">
                PAID BY
              </th>
             
              <th scope="col" className="px-6 py-3">
                TRACKING ID
              </th>
              <th scope="col" className="px-6 py-3">
                DATE
              </th>
            </tr>
          </thead>
          <tbody>
            {searchResults.length > 0
              ? searchResults.map((record, index) => (
                  <tr key={index}>
                    <td
                      className={`px-4 py-2 font-bold text-lg ${
                        record.type === "Withdrawal"
                          ? "text-red-500"
                          : "text-green-500"
                      }`}
                    >
                      {record.type}
                    </td>
                    <td className="px-4 py-2 font-bold text-lg">
                      {record.from}
                    </td>
                    <td className="px-4 py-2 font-bold text-lg">
                    ₦{record.PayeeAmount ? record.PayeeAmount.toLocaleString("en-US") : '0'}
                    </td>
                    <td className="px-4 py-2 font-bold text-lg">
                      {record.date}
                    </td>
                  </tr>
                ))
              : records.map((record, index) => (
                  <tr key={index}>
                    <td
                      className={`px-4 py-2 font-bold text-lg ${
                        record.type === "Withdrawal"
                          ? "text-red-500"
                          : "text-green-500"
                      }`}
                    >
                      {record.type}
                    </td>
                    <td className="px-4 py-2 font-bold text-lg">
                      ₦{record.PayeeAmount ? record.PayeeAmount.toLocaleString("en-US") : '0'}
                    </td>
                    <td className="px-4 py-2 font-bold text-lg">
                      {record.PaymentDescription}
                    </td>
                    <td className="px-4 py-2 font-bold text-lg">
                     
                      {record.PayeePurpose}
                    </td>
                    <td className="px-4 py-2 font-bold text-lg">
                      {record.payeeName}
                    </td>
                    <td className="px-4 py-2 font-bold text-lg">
                      {record.transactionId}
                    </td>
                    <td className="px-4 py-2 font-bold text-lg">
                    {record.createdAt ? formatTimestamp(record.createdAt) : 'No date available'}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DepartFinance;
