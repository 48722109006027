
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db, storage } from "../../firebase";
import {
  Firestore,
  addDoc,
  collection,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";

const PayRoll = () => {
  const [products, setProducts] = useState([]);

  const fetchProducts = async () => {
    await getDocs(collection(db, "payroll")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setProducts(newData);
      console.log(newData);
    });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div>
      <Link to={"/addPayroll"}>
        <button
          type="button"
          class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
        >
          Add To Payroll
        </button>
      </Link>

      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                 Staff Name
              </th>
              <th scope="col" class="px-6 py-3">
                Department
              </th>
              <th scope="col" class="px-6 py-3">
                Salary
              </th>
              
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr class="bg-white border-b  dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-black"
                >
                  {product?.name}
                </th>
                <td class="px-6 py-4">{product?.department}</td>
                <td class="px-6 py-4">${product?.salary_amount}</td>
               
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PayRoll;
