import React, { useState } from "react";
import { auth, db } from "../firebase";
import { toast } from "react-toastify";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

const CreateDepart = () => {
  const [department, setDepartment] = useState("");
  const [profitability, setProfitability] = useState("");
  const [head, setHead] = useState("");
  const [departmentalEmail, setDepartmentalEmail] = useState("");
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);

  async function uploadDepartmentDetails() {
    if (!department || !head || !departmentalEmail || !category) {
      toast.error(
        "Please fill in all required fields (Department Name, Manager's Name, Departmental Email, and Category)"
      );
      return;
    }

    setLoading(true);

    try {
      const docRef = await addDoc(collection(db, "BCS"), {
        department_name: department,
        profitability: profitability || null,
        manager_name: head,
        balance: 0,
        totalBalance: 0,
        departmental_email: departmentalEmail,
        category: category,
        created_by: auth.currentUser.email,
        created_at: serverTimestamp(),
      });

      setLoading(false);
      toast.success("Department details saved!");
    } catch (e) {
      toast.error("An error occurred!");
      setLoading(false);
    }
  }

  return (
    <div className="min-h-screen p-6 bg-gray-100 flex items-center justify-center">
      <div className="container max-w-screen-lg mx-auto">
        <div>
          <h2 className="font-semibold text-2xl text-gray-700 mb-4">
            Add Department/ Fellowship/ Bodies
          </h2>
          <div className="bg-white rounded shadow-lg p-4 md:p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <label
                  className="block text-sm font-medium text-gray-600 mb-1"
                  htmlFor="department"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="department"
                  id="department"
                  className="h-10 border rounded px-4 w-full bg-gray-100"
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium text-gray-600 mb-1"
                  htmlFor="head"
                >
                  Head
                </label>
                <input
                  type="text"
                  name="head"
                  id="head"
                  className="h-10 border rounded px-4 w-full bg-gray-100"
                  value={head}
                  onChange={(e) => setHead(e.target.value)}
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium text-gray-600 mb-1"
                  htmlFor="departmentalEmail"
                >
                  Email
                </label>
                <input
                  type="text"
                  name="departmentalEmail"
                  id="departmentalEmail"
                  className="h-10 border rounded px-4 w-full bg-gray-100"
                  value={departmentalEmail}
                  onChange={(e) => setDepartmentalEmail(e.target.value)}
                />
              </div>
              <div className="flex items-center  w-96 ">
                <label className="block text-lg  font-medium text-blue-600 ">
                  Profitability
                </label>
                <input
                  type="radio"
                  id="profitable"
                  name="profitability"
                  value="Profitable"
                  className="ml-2"
                  checked={profitability === "Profitable"}
                  onChange={() => setProfitability("Profitable")}
                />
                <label htmlFor="profitable" className="ml-2">
                  Profitable
                </label>
                <input
                  type="radio"
                  id="nonProfitable"
                  name="profitability"
                  value="Non-Profitable"
                  className="ml-2"
                  checked={profitability === "Non-Profitable"}
                  onChange={() => setProfitability("Non-Profitable")}
                />
                <label htmlFor="nonProfitable" className="ml-2">
                  Non-Profitable
                </label>
              </div>
              <div className="flex items-center ml-14">
                <label className="block text-lg font-medium text-blue-600">
                  Category
                </label>
                <select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  className="ml-2 border rounded py-2 px-3"
                >
                  <option value="Fellowship">Fellowship</option>
                  <option value="Department">Department</option>
                  <option value="Groups">Groups</option>
                  <option value="Priestly Bodies">Priestly Bodies</option>
                  <option value="Events">Events</option>
                  <option value="Area">Area</option>
                  <option value="Zone">Zone</option>
                  <option value="Bethel">Bethel</option>
                  <option value="States">States/Nation</option>
                  <option value="Tithes">Tithes/Donation</option>
                </select>
              </div>

              {/* <div className="flex items-center ml-14">
                <label className="block text-lg  font-medium text-blue-600 ">
                  Category
                </label>
                <select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="Fellowship">Fellowship</option>
                  <option value="Bodies">Bodies</option>
                  <option value="Groups">Groups</option>
                  <option value="Priestly Bodies">Priestly Bodies </option>
                  <option value="Events">Events</option>
                  <option value="Tithes/Donation">Tithes/Donation</option>
                </select>
              </div> */}
            </div>
            <div className="mt-4 flex justify-end">
              <button
                disabled={loading}
                className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
                onClick={() => uploadDepartmentDetails()}
              >
                {loading ? "Saving..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDepart;
