import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { auth, db } from "../firebase";
import { collection, getDocs, where, query } from "firebase/firestore";
import { useUser } from "../components/user-info/UserContext";
import Son from "../assets/he.png";
import { useNavigate } from "react-router-dom";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setUserDepartment } = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { userDepartment } = useUser();
  const [showPassword, setShowPassword] = useState(false);

  const fetchUsers = async (userId) => {
    const querySnapshot = await getDocs(collection(db, "DepartUsers"));

    const newData = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }))
      .filter((user) => user.uid === userId);

    return newData;
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    SignIn(); // Call your sign-in function here
  };
  // Inside your component where you handle user login:

  async function SignIn() {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Get the user's email from the authentication
      const user = userCredential.user;

      // Fetch the user's details from Firestore
      const userResult = await fetchUsers(user.uid);

      if (userResult.length > 0) {
        // User details for the logged-in user
        const loggedInUserDetails = userResult[0];

        // You can access user details such as department, name, etc.
        // console.log(loggedInUserDetails);
        const userName = loggedInUserDetails.name;

        // Use the user details as needed
        setUserDepartment(loggedInUserDetails);

        navigate(`/department/${loggedInUserDetails.department}`);
        // console.log(loggedInUserDetails);

        // }
        toast.success("Login successful");
      } else {
        toast.error("User not found");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred");
    }
    finally {
      setLoading(false); 
    }
  }

  return (
    <section
      class="bg-white w-screen h-screen lg:flex grid"
    >
      <div className="flex lg:w-[50%] w-[100%] flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <img class=" lg:ml-32 rounded-2xl shadow-2xl " src={Son} alt="logo" />
      </div>

      <div class="flex lg:w-[50%] w-[100%] flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0" 
      >
        <div class="w-full bg-white rounded-2xl  md:mt-0 sm:max-w-md xl:p-0">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight md:text-2xl text-gray-600">
              Sign in to your Dashboard
            </h1>
            <form class="space-y-4 md:space-y-6" action="#" onSubmit={handleSubmit}>
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-600"
                >
                  Your Email
                </label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      SignIn();
                    }
                  }}
                  type="email"
                  name="email"
                  id="email"
                  class="rounded-lg  block w-full p-3 text-sm shadow-2xl bg-blue-100 border-gray-500 placeholder-gray-600 text-black"
                  placeholder="email@ooobcs.org"
                  required=""
                />
              </div>
              <div className="pb-10">
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-600"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        SignIn();
                      }
                    }}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="input your password here"
                    class="rounded-lg block w-full p-3 text-sm shadow-2xl bg-blue-100 border-gray-500 placeholder-gray-600 text-black"
                    required=""
                  />
                  {/* Toggle password visibility */}
                  {showPassword ? (
                    <EyeOutlined
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    < EyeInvisibleOutlined
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )}
                </div>
              </div>
           <div
            onClick={handleSubmit}
            className={`${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            } bg-gray-600 hover:bg-gray-800 shadow-2xl font-medium rounded-lg text-sm px-5 py-5 text-center text-white cursor-pointer`}
          >
            {loading ? 'Signing In...' : 'Sign In'}
          </div>
            </form>
          </div>
        </div>
      {/* <div className="">
        BCS HR V 4.0 
      </div> */}
      </div>

    </section>
  );
};

export default Login;
