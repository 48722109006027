import React, { useEffect, useState } from "react";
import { Table, Space, Modal, Button, Input } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

const ApprovedRetirements = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [queryInput, setQueryInput] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const {Search} = Input;

  const showModal = (request) => {
    setSelectedRequest(request);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "retirements"));
        const requestData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRequests(requestData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching retirements:", error);
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  const columns = [
    {
      title: "Fellowship",
      dataIndex: "fellowship",
      width: 150,
    },
    {
      title: "Date",
      dataIndex: "date",
      width: 120,
    },
    {
      title: "Topic",
      dataIndex: "topic",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => showModal(record)} className="bg-blue-500 text-white">
            View
          </Button>
        </Space>
      ),
      width: 200,
    },
    {
      title: "Approval Status",
      dataIndex: "approved",
      render: (text, record) => (
        <Space size="middle">
          {record.approved && (
            <span style={{ color: "green" }}>
              <CheckCircleOutlined />
            </span>
          )}
        </Space>
      ),
      width: 150,
    },
  ];
  const handleSearch = (value) => {
    setQueryInput(value);
  };
  
  const filteredRequests = requests.filter((request) => {
    // Filter based on the search input (you can customize this logic)
    return (
      (request.fellowship &&
        request.fellowship.toLowerCase().includes(queryInput.toLowerCase())) ||
      (request.date &&
        request.date.toLowerCase().includes(queryInput.toLowerCase())) ||
      (request.topic &&
        request.topic.toLowerCase().includes(queryInput.toLowerCase()))
    );
  });
  
  return (
    <div className="mt-10 p-10 ml-10">
      <h1>Retirements</h1>
      <Search
        placeholder="Search requests..."
        // onSearch={handleSearch}
        onChange={(e) => handleSearch(e.target.value)}
        // value={searchText}
        style={{ width: "80%", maxWidth: "300px", marginBottom: "1rem" }}
        // enterButton
      />
      <Table
        loading={loading}
        columns={columns}
        dataSource={filteredRequests}
        pagination={{
          pageSize: 5,
        }}
        scroll={{ x: "max-content" }}
      />

      <Modal
        title="Request Details"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="70%"
      >
        {selectedRequest && (
          <div className="p-4 w-[100%]">
            <div className="mb-4">
              <p className="text-lg font-bold">Date: {selectedRequest.date}</p>
              <p className="text-lg font-bold">
                Fellowship: {selectedRequest.fellowship}
              </p>
            </div>
            <div className="mb-4">
              <p className="text-xl font-bold">
                Title: {selectedRequest.topic}
              </p>
            </div>
            <div className="mb-4">
              <p>The Supervisor BCS Establishments / Departments</p>
              <p className="mb-2">
                I hope this letter finds you well. We would like to bring to
                your attention the following request details:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>
                  <strong>Requisition:</strong>
                  {selectedRequest.attachment && (
                    <a
                      href={selectedRequest.attachment.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline ml-1"
                    >
                      {selectedRequest.attachment.name}
                    </a>
                  )}
                </li>
                <li>
                  <strong>Head :</strong> {selectedRequest.Head}
                </li>
                <li>
                  <strong>Finance Officer : </strong>{" "}
                  {selectedRequest.financeOfficer}
                </li>
                <li>
                  <strong>Treasurer : </strong> {selectedRequest.treasurer}
                </li>
              </ul>
              <p className="mb-4">
                We kindly request your approval for this matter. Your prompt
                attention to this request is highly appreciated.
              </p>
              <p className="mb-4">Thank you for your cooperation.</p>
              <p>Sincerely,</p>
              <p>Your Name</p>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ApprovedRetirements;
