import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// import "firebase/compat/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBo9CUsKlEGUkYTsjdTy5n6Q3X9i7ec-RQ",
  authDomain: "bcs-public-portal.firebaseapp.com",
  projectId: "bcs-public-portal",
  storageBucket: "bcs-public-portal.appspot.com",
  messagingSenderId: "823547668549",
  appId: "1:823547668549:web:4f556bc096f5d480e49b2c",
  measurementId: "G-VEHR0EV9P3"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    // User is signed in
    console.log("User is signed in:", user);
  } else {
    // User is signed out
    console.log("User is signed out");
  }
});

export default firebase;