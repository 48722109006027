import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db, storage } from "../../firebase";
import { Table, Space, Button } from "antd";
// import "antd/dist/antd.css"; 
import {
  Firestore,
  addDoc,
  collection,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";

const ProductTable = () => {
  const [products, setProducts] = useState([]);

  const fetchProducts = async () => {
    await getDocs(collection(db, "Staff")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setProducts(newData);
      console.log(products.id);
    });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const columns = [
    {
      title: "Name of Employee",
      dataIndex: "fullName",
      key: "fullName",
      render: (text, record) => (
        <Link to={`/query/${record.id}`}>
          {`${record.last_name} ${record.other_names}`}
        </Link>
      ),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleQuery(record.id)}>
            Query
          </Button>
        </Space>
      ),
    },
  ];

  const handleQuery = (productId) => {
    // Handle query logic, e.g., redirect to a query page
    console.log(`Query for product with ID: ${productId}`);
  };
  return (
    <div className="md:ml-20">
      <Link to={"/create_product"}>
        <Button type="primary" className="mb-2 bg-blue-500">
          Add a new Employee
        </Button>
      </Link>

      <Table
        dataSource={products}
        columns={columns}
        pagination={false} 
        rowKey={(record) => record.id}
      />
    </div>
  );
};

export default ProductTable;
