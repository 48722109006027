import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

const EditDepartment = () => {
  const { id } = useParams();
  const [department, setDepartment] = useState({
    department_name: "",
    profitability: "",
    manager_name: "",
    departmental_email: "",
    category: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const docRef = doc(db, "BCS", id);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setDepartment(data);
        } else {
          console.error("Document not found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDepartmentData();
  }, [id]);

  async function updateDepartmentDetails() {
    try {
      setLoading(true);

      const docRef = doc(db, "BCS", id);
      const updatedData = {
        department_name: department.department_name,
        profitability: department.profitability || null,
        manager_name: department.manager_name,
        departmental_email: department.departmental_email,
        category: department.category,
      };

      await updateDoc(docRef, updatedData);

      setLoading(false);
      console.log("Department details updated!");
    } catch (e) {
      console.error("An error occurred:", e);
      setLoading(false);
    }
  }

  return (
    <div className="min-h-screen p-6 bg-gray-100 flex items-center justify-center">
      <div className="container max-w-screen-md mx-auto">
        <div>
          <h2 className="font-semibold text-xl md:text-2xl text-gray-700 mb-4">
            Edit Department: {department.department_name}
          </h2>
          <div className="bg-white rounded shadow-lg p-4 md:p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label
                  className="block text-sm font-medium text-gray-600 mb-1"
                  htmlFor="department"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="department"
                  id="department"
                  className="h-10 border rounded px-4 w-full bg-gray-100"
                  value={department.department_name}
                  onChange={(e) =>
                    setDepartment({
                      ...department,
                      department_name: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium text-gray-600 mb-1"
                  htmlFor="head"
                >
                  Head
                </label>
                <input
                  type="text"
                  name="head"
                  id="head"
                  className="h-10 border rounded px-4 w-full bg-gray-100"
                  value={department.manager_name}
                  onChange={(e) =>
                    setDepartment({
                      ...department,
                      manager_name: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium text-gray-600 mb-1"
                  htmlFor="departmentalEmail"
                >
                  Email
                </label>
                <input
                  type="text"
                  name="departmentalEmail"
                  id="departmentalEmail"
                  className="h-10 border rounded px-4 w-full bg-gray-100"
                  value={department.departmental_email}
                  onChange={(e) =>
                    setDepartment({
                      ...department,
                      departmental_email: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex items-center">
                <label className="block text-lg font-medium text-blue-600">
                  Profitability
                </label>
                <div className="ml-2">
                  <input
                    type="radio"
                    id="profitable"
                    name="profitability"
                    value="Profitable"
                    checked={department.profitability === "Profitable"}
                    onChange={() =>
                      setDepartment({
                        ...department,
                        profitability: "Profitable",
                      })
                    }
                  />
                  <label htmlFor="profitable" className="ml-2">
                    Profitable
                  </label>
                </div>
                <div className="ml-2">
                  <input
                    type="radio"
                    id="nonProfitable"
                    name="profitability"
                    value="Non-Profitable"
                    checked={department.profitability === "Non-Profitable"}
                    onChange={() =>
                      setDepartment({
                        ...department,
                        profitability: "Non-Profitable",
                      })
                    }
                  />
                  <label htmlFor="nonProfitable" className="ml-2">
                    Non-Profitable
                  </label>
                </div>
              </div>
              <div className="flex items-center">
                <label className="block text-lg font-medium text-blue-600">
                  Category
                </label>
                <select
                  value={department.category}
                  onChange={(e) =>
                    setDepartment({
                      ...department,
                      category: e.target.value,
                    })
                  }
                  className="ml-2 border rounded py-2 px-3 w-full md:w-auto"
                >
                  <option value="Fellowship">Fellowship</option>
                  <option value="Department">Department</option>
                  <option value="Groups">Groups</option>
                  <option value="Priestly Bodies">Priestly Bodies</option>
                  <option value="Events">Events</option>
                  <option value="Area">Area</option>
                  <option value="Zone">Zone</option>
                  <option value="Bethel">Bethel</option>
                  <option value="States">States/Nation</option>
                  <option value="Tithes">Tithes/Donation</option>
                </select>
              </div>
            </div>
            <div className="mt-4 flex justify-end">
              <button
                disabled={loading}
                className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
                onClick={() => updateDepartmentDetails()}
              >
                {loading ? "Saving..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDepartment;
