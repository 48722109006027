import React, { useEffect, useState } from 'react'
import './summary-box.scss'
import Box from '../box/Box'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { colors } from '../../constants';
import { Firestore, addDoc, collection, collectionGroup, getDocs, serverTimestamp } from 'firebase/firestore';
import { Line } from 'react-chartjs-2';
import { Link, useLocation } from 'react-router-dom'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'
import { db } from '../../firebase'
import firebase from "../../firebaseconfig";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const SummaryBox8 = () => {
    const [balance, setBalance] = useState([]);


     useEffect(() => {
    const db = firebase.firestore();
    const transactionRef = db.collection("BCS");
  
    transactionRef
      .where("category", "==", "Department") // Filter by the category "Department"
      .get()
      .then((querySnapshot) => {
        const data = [];
        let totalBalance = 0; // Initialize total balance
  
        querySnapshot.forEach((doc) => {
          const transactionData = doc.data();
          data.push(transactionData);
  
          // Check if "balance" exists and add it to the total
          if (transactionData.balance) {
            totalBalance += transactionData.balance;
          }
        });
  
        setBalance(totalBalance); // Set the total balance in the state
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);


    return (
        <Link to={'departbalance'}>
            <Box>
                <div className='summary-box'>
                    <div className="summary-box__info">
                        <div className="summary-box__info__title">
                            <div>Queries</div>
                            <span>All Queries</span>
                        </div>
                        <div className="summary-box__info__value">
                        {
                        // balance ? balance.toLocaleString("en-US") : 
                        '0'}
                        </div>
                    </div>
                    {/* <div className="summary-box__chart">
                        <CircularProgressbarWithChildren
                            value={70}
                            strokeWidth={10}
                            styles={buildStyles({
                                pathColor: 30 < 50 ? colors.red : colors.purple,
                                trailColor: 'transparent',
                                strokeLinecap: 'round'
                            })}
                        >
                            <div className="summary-box__chart__value">
                                {30}%
                            </div>
                        </CircularProgressbarWithChildren>
                    </div> */}
                </div>
            </Box>
        </Link>
    )
}


export default SummaryBox8


