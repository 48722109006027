import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { Table, Space, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const Fellowship = () => {
  const [departments, setDepartments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchDepartments = async () => {
      setLoading(true)
      const querySnapshot = await getDocs(collection(db, "BCS"));
      const departmentsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        staffCount: 0, // Initialize staff count to 0
      }));

      // Filter departments by category (e.g., "Department")
      const filteredFellowships = departmentsData.filter(
        (department) => department.category === "Fellowship"
      );

      // Fetch staff count for each department
      const staffQuerySnapshot = await getDocs(collection(db, "Staff"));
      const staffData = staffQuerySnapshot.docs.map((doc) => doc.data());

      // Count staff members for each department
      for (const department of departmentsData) {
        const staffInDepartment = staffData.filter(
          (staff) => staff.department === department.department_name
        );
        department.staffCount = staffInDepartment.length;
      }

      setDepartments(departmentsData);
      setLoading(false)
    };

    fetchDepartments();
  }, []);

  const handleDeleteDepartment = async (departmentId) => {
    // Delete the department from the database
    try {
      await deleteDoc(doc(db, "BCS", departmentId));
      // Filter out the deleted department from the state
      setDepartments((prevDepartments) =>
        prevDepartments.filter((dept) => dept.id !== departmentId)
      );
      alert("Department deleted successfully!");
    } catch (error) {
      console.error("Error deleting department: ", error);
      alert("Failed to delete department. Please try again.");
    }
  };

  const filteredDepartments = departments.filter((department) =>
    department.department_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );
  const columns = [
    {
      title: "Name",
      dataIndex: "department_name",
      key: "department_name",
      render: (text, record) => (
        <Link to={`/department/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Head",
      dataIndex: "manager_name",
      key: "manager_name",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space>
          <Link to={`/department/${record.id}`}>View</Link>
          <Link to={`/edit/${record.id}`}>Edit</Link>
          {/* <button
            onClick={() => handleDeleteDepartment(record.id)}
            className="text-red-500 hover:underline"
          >
            Delete
          </button> */}
        </Space>
      ),
    },
  ];

  return (
    <div className="md:ml-20">
      <div className="flex">
        <Link to={"/create-depart"}>
          <button
            type="button"
            className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
          >
            Add
          </button>
        </Link>

        <div className="lg:ml-[10%] mb-5">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <Table
          columns={columns}
          loading={loading}
          dataSource={filteredDepartments}
          pagination={{ pageSize: 10 }}
          className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        />
      </div>
    </div>
  );
};

export default Fellowship;
