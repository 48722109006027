    import React, { useState } from 'react';
    import { Link, useParams } from 'react-router-dom';
    import ReactQuill from 'react-quill';
    import 'react-quill/dist/quill.snow.css'; // Import styles for react-quill

    const Query = () => {
      const { product_id } = useParams(); // Get the product ID from the URL

      const [editorHtml, setEditorHtml] = useState('');

      const handlePrint = () => {
        // You can use the browser's print function to print the content.
        window.print();
      };

      return (
        <div>
          <h1>Product ID: {product_id}</h1>
          <div>
            <ReactQuill
              value={editorHtml}
              onChange={setEditorHtml}
              theme="snow" // You can choose a different theme if needed
            />
          </div>
          <button onClick={handlePrint}>Print</button>
        </div>
      );
    };

    export default Query
