import React from "react";
import "./user-info.scss";
import { auth } from "../../firebase";
import { Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { IoLogoBuffer } from "react-icons/io";
import { useUser } from "./UserContext";
import { toast } from "react-toastify";
import { AiOutlineHome } from "react-icons/ai";
import { BsPeopleFill } from "react-icons/bs";
import { MdOutlinePayments } from "react-icons/md";
import { MdGroups } from "react-icons/md";
import { GiOpenTreasureChest } from "react-icons/gi";
import { FcDepartment } from "react-icons/fc";
import { CiLogout } from "react-icons/ci";

const UserInfo = ({ user }) => {
  const { userDepartment } = useUser();

  // Menu items for the dropdown
  const menu = (
    <Menu theme="light" mode="vertical">
      {userDepartment?.role === "others" && (
        <>
          <Menu.Item key="department" icon={<FcDepartment />}>
            <Link to={`/department/${userDepartment?.department}`}>
              {userDepartment?.name}
            </Link>
          </Menu.Item>

          <Menu.Item
            key="2"
            onClick={() => {
              auth
                .signOut()
                .then((res) => toast.success("You have been logged out!"))
                .catch((err) => {
                  toast.error("An Error Occurred!");
                });
            }}
            icon={<CiLogout />}
          >
            <Link to={"/"}>Logout</Link>
          </Menu.Item>
        </>
      )}

      {userDepartment?.role === "TitheOffice" && (
        <>
          <Menu.Item key="dashboard" icon={<AiOutlineHome />}>
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>

          <Menu.Item key="products" icon={<BsPeopleFill />}>
            <Link to="/products">Staffs</Link>
          </Menu.Item>

          <Menu.Item key="finance" icon={<GiOpenTreasureChest />}>
            <Link to="/finance">Finance</Link>
          </Menu.Item>

          <Menu.Item key="bcs">
            <Link to="/bcs" icon={<FcDepartment />}>
              BCS
            </Link>
          </Menu.Item>

          <Menu.Item key="departs" icon={<MdGroups />}>
            <Link to="/departs">Departments</Link>
          </Menu.Item>

          <Menu.Item key="ict" icon={<MdOutlinePayments />}>
            <Link to="/ict">PayRoll</Link>
          </Menu.Item>

          <Menu.Item
            key="signOut"
            onClick={() => {
              auth
                .signOut()
                .then((res) => toast.success("You have been logged out!"))
                .catch((err) => {
                  toast.error("An Error Occurred!");
                });
            }}
            icon={<CiLogout />}
          >
            <Link to={"/"}>Logout</Link>
          </Menu.Item>
        </>
      )}

      {userDepartment?.role === "Ict" && (
        <>
          <Menu.Item key="dashboard" icon={<AiOutlineHome />}>
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>

          <Menu.Item key="products" icon={<BsPeopleFill />}>
            <Link to="/products">Staffs</Link>
          </Menu.Item>

          <Menu.Item key="financeA" icon={<GiOpenTreasureChest />}>
            <Link to="/financeA">Finance Attempts</Link>
          </Menu.Item>

          <Menu.Item key="finance" icon={<GiOpenTreasureChest />}>
            <Link to="/finance">Finance</Link>
          </Menu.Item>

          <Menu.Item key="Signup" icon={<BsPeopleFill />}>
            <Link to="/Signup">Create User</Link>
          </Menu.Item>

          <Menu.Item key="bcs" icon={<FcDepartment />}>
            <Link to="/bcs">BCS</Link>
          </Menu.Item>
          <Menu.Item key="departs" icon={<MdGroups />}>
            <Link to="/departs">Departments</Link>
          </Menu.Item>

          <Menu.Item key="ict" icon={<MdOutlinePayments />}>
            <Link to="/ict">PayRoll</Link>
          </Menu.Item>

          <Menu.Item
            key="/"
            onClick={() => {
              auth
                .signOut()
                .then((res) => toast.success("You have been logged out!"))
                .catch((err) => {
                  toast.error("An Error Occurred!");
                });
            }}
            icon={<CiLogout />}
          >
            <Link to={"/"}>Logout</Link>
          </Menu.Item>
        </>
      )}

      {userDepartment?.role === "AuditUnit" && (
        <>
          <Menu.Item key="0" icon={<AiOutlineHome />}>
            <Link to="/viewretirements">Retirements</Link>
          </Menu.Item>

          <Menu.Item
            key="1"
            onClick={() => {
              auth
                .signOut()
                .then((res) => toast.success("You have been logged out!"))
                .catch((err) => {
                  toast.error("An Error Occurred!");
                });
            }}
            icon={<CiLogout />}
          >
            <Link to={"/"}>Logout</Link>
          </Menu.Item>
        </>
      )}

      {userDepartment?.role === "Supervisor" && (
        <>
          <Menu.Item key="dashboard" icon={<AiOutlineHome />}>
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>

          <Menu.Item key="1" icon={<BsPeopleFill />}>
            <Link to="/products">Staffs</Link>
          </Menu.Item>

          <Menu.Item key="2" icon={<MdGroups />}>
            <Link to="/departs">Departments</Link>
          </Menu.Item>

          <Menu.Item key="3" icon={<GiOpenTreasureChest />}>
            <Link to="/departbalance">All Dept Balance</Link>
          </Menu.Item>

          <Menu.Item key="4" icon={<GiOpenTreasureChest />}>
            <Link to="/viewrequisition">Requisitions</Link>
          </Menu.Item>

          <Menu.Item key="5" icon={<GiOpenTreasureChest />}>
            <Link to="/accepted">Accepted Requisitions</Link>
          </Menu.Item>

          <Menu.Item
            key="6"
            onClick={() => {
              auth
                .signOut()
                .then((res) => toast.success("You have been logged out!"))
                .catch((err) => {
                  toast.error("An Error Occurred!");
                });
            }}
            icon={<CiLogout />}
          >
            <Link to={"/"}>Logout</Link>
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  return (
    <>
      <div className="user-info md:ml-40 -mr-20">
        <div className="user-info__name">
          <span className="text-lg">{auth.currentUser.email}</span>
          {/* <Menu.Item
                  key="2"
                  onClick={() => {
                    auth
                      .signOut()
                      .then((res) => toast.success("You have been logged out!"))
                      .catch((err) => {
                        toast.error("An Error Occurred!");
                      });
                  }}
                  icon={<CiLogout />}

                > */}
                  <Link to={"/"}
                  className="text-sm text-blue-500"
                   onClick={() => {
                    auth
                      .signOut()
                      .then((res) => toast.success("You have been logged out!"))
                      .catch((err) => {
                        toast.error("An Error Occurred!");
                      });
                  }}
                  >  Logout
                  </Link>
                {/* </Menu.Item> */}
        </div>
      </div>
      <div className="mr-2 md:hidden">
        <Dropdown overlay={menu} trigger={["click"]}>
          <IoLogoBuffer size={20} className="cursor-pointer" />
        </Dropdown>
      </div>
    </>
  );
};

export default UserInfo;
