import React, { useEffect, useState } from "react";
import { Table, Space, Button, Input } from "antd";
import firebase from "../../firebaseconfig";
import { BiToggleRight, BiToggleLeft } from "react-icons/bi";
import { toast } from "react-toastify";

const FinanceA = () => {
  const db = firebase.firestore();
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false)
  const {Search} = Input;

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => (
        <span
          className={`font-bold text-sm ${
            record.type === "Withdrawal" ? "text-red-500" : "text-yellow-600"
          }`}
        >
          Income Attempt
        </span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "PayeeAmount",
      key: "PayeeAmount",
      render: (text, record) => (
        <span className="font-bold text-sm">
          ₦{record.PayeeAmount ? record.PayeeAmount.toLocaleString("en-US") : "0"}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "payeeEmail",
      key: "payeeEmail",
      render: (text, record) => (
        <span className="font-bold text-sm">{record.payeeEmail}</span>
      ),
    },
    {
      title: "Department",
      dataIndex: "PayeePurpose",
      key: "PayeePurpose",
      render: (text, record) => (
        <span className="font-bold text-sm">{record.PayeePurpose}</span>
      ),
    },
    {
      title: "Paid By",
      dataIndex: "payeeName",
      key: "payeeName",
      render: (text, record) => (
        <span className="font-bold text-sm">{record.payeeName}</span>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "payeePhoneNumber",
      key: "payeePhoneNumber",
      render: (text, record) => (
        <span className="font-bold text-sm">{record.payeePhoneNumber}</span>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <span className="font-bold text-sm">
          {record.createdAt ? formatTimestamp(record.createdAt) : "No date available"}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "toggle",
      key: "toggle",
      render: (text, record) => (
        <Space size="middle">
          {firebase.auth().currentUser.email === "bcsict@ooobcs.org" && (
            <>
              {!record.toggle ? (
                <Button
                  type="primary"
                  className="bg-blue-500"
                  onClick={async () => {
                    const confirmed = window.confirm("You're about to toggle this transaction?");
                  
                    if (confirmed) {
                    try {
                      await db.collection("Transactions").add(record);

                      await db
                        .collection("Attempts")
                        .doc(record.id)
                        .set({ toggle: true }, { merge: true });

                      const balanceDoc = await db.collection("BCS").doc(record.bcsid).get();
                      const ICTbalanceDoc = await db.collection("BCS").doc("tj2dBnlhMZLs1aXEVbYT").get();

                      const currentBalance = balanceDoc.exists ? balanceDoc.data().balance : 0;
                      const ICTcurrentBalance = ICTbalanceDoc.exists ? ICTbalanceDoc.data().balance : 0;

                      const TotalCurrentBalance = balanceDoc.exists ? balanceDoc.data().totalBalance : 0;
                      const ICTTotalCurrentBalance = ICTbalanceDoc.exists ? ICTbalanceDoc.data().totalBalance : 0;

                      const newBalance = currentBalance + record.PayeeAmount - 100
                      const ICTnewBalance = ICTcurrentBalance + 100

                      const TotalNewBalance = TotalCurrentBalance + record.PayeeAmount
                      const ICTTotalNewBalance = ICTTotalCurrentBalance + 100
                      // const newBalance = currentBalance + record.PayeeAmount - (record.bcsid === "tj2dBnlhMZLs1aXEVbYT" ? 0 : 100);

                      await db
                        .collection("BCS")
                        .doc(record.bcsid)
                        .set({ balance: newBalance, totalBalance: TotalNewBalance }, { merge: true });

                      await db
                        .collection("BCS")
                        .doc("tj2dBnlhMZLs1aXEVbYT")
                        .set({ balance: ICTnewBalance, totalBalance: ICTTotalNewBalance }, { merge: true });

                      console.log("Attempted data saved successfully");
                      alert("Successful")
                    } catch (error) {
                      console.error("Error saving payment data: ", error);
                    }
                  }
                }}
                >
                  <BiToggleLeft size={20} />
                </Button>
              ) : (
                <Button type="primary" disabled>
                  <BiToggleRight size={20} />
                </Button>
              )}
            </>
          )}
        </Space>
      ),
    },
  ];

  const fetchRecords = async () => {
    try {
      // setLoading(true)
      const snapshot = await db.collection("Attempts").orderBy("createdAt", "desc").get();
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecords(data);
      setLoading(false)
    } catch (error) {
      toast.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchRecords();
  }, [records]);
  

  const formatTimestamp = (timestamp) => {
    if (timestamp) {
      const date = timestamp.toDate(); // Convert to JavaScript Date
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    } else {
      return "Invalid Timestamp";
    }
  };

  const handleSearch = (value) => {
    const lowerCaseSearchTerm = value.toLowerCase();
    const filteredRecords = records.filter((record) => {
      return (
        (record.PayeeAmount && record.PayeeAmount.toString().includes(lowerCaseSearchTerm)) ||
        (record.payeeEmail && record.payeeEmail.includes(lowerCaseSearchTerm)) ||
        (record.payeeName && record.payeeName.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (record.PayeePurpose && record.PayeePurpose.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (record.payeePhoneNumber && record.payeePhoneNumber.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (record.type && record.type.toLowerCase().includes(lowerCaseSearchTerm)));
    });
    setSearchResults(filteredRecords);
  };

  return (
    <div className="md:ml-20">
      <div className="flex justify-center">
        <div className="shadow-lg p-5 h-44 w-96 shadow-blue-200 bg-[#3ec9d6] rounded-md text-center ">
          <h2 className="font-extrabold text-5xl text-white mt-9">All Atttempts</h2>
        </div>
      </div>
      <Search
        placeholder="Search by Name, Amount, Date, or Purpose"
        onSearch={handleSearch}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        style={{ width: "100%", maxWidth: "400px", marginBottom: "1rem" }}
        className="bg-blue-400 rounded-xl"
        enterButton
      />

      {/* <div className="mt-20">
        <Table
          dataSource={searchResults.length > 0 ? searchResults : records}
          columns={columns}
          pagination={false} // Disable pagination for now, adjust as needed
          rowKey={(record) => record.id}
        />
      </div> */}
      <div className="mt-20 relative overflow-x-auto shadow-md sm:rounded-lg">
        <Table
          columns={columns}
          loading={loading}
          dataSource={searchResults.length > 0 ? searchResults : records}
          pagination={{
            pageSize: 10,
          }}
          // scroll={{ x: "max-content" }}
          className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        />
      </div>
    </div>
  );
};

export default FinanceA;

// import React, { useEffect, useState } from "react";
// import { AiOutlineRollback } from "react-icons/ai";
// import { BsArrowBarRight } from "react-icons/bs";
// import { BsArrowBarDown } from "react-icons/bs";
// import { Link } from "react-router-dom";
// import Data from "./Data";
// import firebase from "../../firebaseconfig";
// // import firebase from 'firebase/compat/app';
// import data2 from "./data2";
// import { FaBitcoin } from "react-icons/fa";
// import { BiSolidToggleRight, BiToggleLeft } from "react-icons/bi";

// const FinanceA = () => {
//   const db = firebase.firestore();
//   const [departmentData, setDepartmentData] = useState({});
//   const [records, setRecords] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [searchResults, setSearchResults] = useState([]);
//   const [balance, setBalance] = useState();

//   const handleSearch = () => {
//     const filteredRecords = records.filter((record) => {
//       return (
//         record.price.includes(searchTerm) ||
//         record.date.includes(searchTerm) ||
//         record.from.includes(searchTerm) ||
//         record.type.includes(searchTerm)
//       );
//     });
//     setSearchResults(filteredRecords);
//   };

//   const handleClearSearch = () => {
//     setSearchTerm("");
//     setSearchResults([]);
//   };

//   useEffect(() => {
//     // const db = firebase.firestore();
//     db.collection("Attempts")
//       .orderBy("createdAt", "desc")
//       .onSnapshot((snapshot) => {
//         const data = snapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));

//         setRecords(data);
//       });
//     // transactionRef
//     // .get()
//     // .then((querySnapshot) => {
//     //   const data = [];
//     //   querySnapshot.forEach((doc) => {
//     //     const transactionData = doc.data();
//     //     data.push({

//     //       // transactionData
//     //       id: doc.id,
//     //     ...doc.data(),

//     //     });
//     //     console.log("Fetched data: ", transactionData);
//     //   });
//     //   setRecords(data);
//     // })
//     // .catch((error) => {
//     //   console.error("Error fetching data: ", error);
//     // });
//   }, []);

//   useEffect(() => {
//     const db = firebase.firestore();
//     const transactionRef = db.collection("BCS");

//     transactionRef
//       .get()
//       .then((querySnapshot) => {
//         const data = [];
//         let totalBalance = 0; // Initialize total balance

//         querySnapshot.forEach((doc) => {
//           const transactionData = doc.data();
//           data.push(transactionData);

//           // Check if "balance" exists and add it to the total
//           if (transactionData.balance) {
//             totalBalance += transactionData.balance;
//           }

//           // console.log("Fetched data: ", transactionData.balance);
//         });

//         setBalance(totalBalance); // Log the total balance
//       })
//       .catch((error) => {
//         console.error("Error fetching data: ", error);
//       });
//   }, []);

//   const formatTimestamp = (timestamp) => {
//     if (timestamp) {
//       const date = timestamp.toDate(); // Convert to JavaScript Date
//       return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
//     } else {
//       return "Invalid Timestamp";
//     }
//   };

//   return (
//     <div className="ml-40">
//       {/* <h1 className="text-gray-500 font-bold text-lg text-center m-5">
//         GENERAL
//       </h1> */}
//       <div className="flex justify-center">
//         <div className="shadow-lg p-5 h-44 w-96 shadow-blue-200 bg-[#3ec9d6] rounded-md text-center">
//           <FaBitcoin size={50} color="#3ec9d6" className="self-center" />
//           <h2 className="font-extrabold text-5xl text-white">All Atttempts</h2>
//         </div>
//       </div>

//       <div className="mt-20">
//         <table className="w-full text-sm text-left dark:text-gray-400">
//           <thead className="uppercase bg-gray-50 dark:bg-gray-700">
//             <tr>
//               <th scope="col" className="px-3 py-3">
//                 Type
//               </th>
//               <th scope="col" className="px-3 py-3">
//                 AMOUNT
//               </th>
//               <th scope="col" className="px-3 py-3">
//                 Email
//               </th>
//               <th scope="col" className="px-3 py-3">
//                 DEPARTMENT
//               </th>
//               <th scope="col" className="px-3 py-3">
//                 PAID BY
//               </th>

//               <th scope="col" className="px-3 py-3">
//                 Phone Number
//               </th>
//               <th scope="col" className="px-3 py-3">
//                 DATE
//               </th>
//               <th scope="col" className="px-3 py-3">
//                 Action
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             {searchResults.length > 0
//               ? searchResults.map((record, index) => (
//                   <tr key={index}>
//                     <td
//                       className={`px-2 py-2 font-bold text-sm ${
//                         record.type === "Withdrawal"
//                           ? "text-red-500"
//                           : "text-red-500"
//                       }`}
//                     >
//                       Income Attempt
//                     </td>
//                     <td className="px-2 py-2 font-bold text-sm">
//                       {record.from}
//                     </td>
//                     <td className="px-2 py-2 font-bold text-sm">
//                       ₦
//                       {record.PayeeAmount
//                         ? record.PayeeAmount.toLocaleString("en-US")
//                         : "0"}
//                     </td>
//                     <td className="px-2 py-2 font-bold text-sm">
//                       {record.date}
//                     </td>
//                   </tr>
//                 ))
//               : records.map((record, index) => (
//                   <tr key={index}>
//                     <td
//                       className={`px-2 py-2 font-bold text-sm ${
//                         record.type === "Withdrawal"
//                           ? "text-yellow-600"
//                           : "text-yellow-600"
//                       }`}
//                     >
//                       Income Attempt
//                     </td>
//                     <td className="px-2 py-2 font-bold text-sm">
//                       ₦
//                       {record.PayeeAmount
//                         ? record.PayeeAmount.toLocaleString("en-US")
//                         : "0"}
//                     </td>
//                     <td className="px-2 py-2 font-bold text-sm">
//                       {record.payeeEmail}
//                     </td>
//                     <td className="px-2 py-2 font-bold text-sm">
//                       {record.PayeePurpose}
//                     </td>
//                     <td className="px-2 py-2 font-bold text-sm">
//                       {record.payeeName}
//                     </td>
//                     <td className="px-2 py-2 font-bold text-sm">
//                       {record.payeePhoneNumber}
//                     </td>
//                     <td className="px-2 py-2 font-bold text-sm">
//                       {record.createdAt
//                         ? formatTimestamp(record.createdAt)
//                         : "No date available"}
//                     </td>

//                     {firebase.auth().currentUser.email ===
//                     "bcsict@ooobcs.org" ? (
//                       <>
//                         {!record.toggle ? (
//                           <td
//                             onClick={async () => {
//                               try {
//                                 await db.collection("Transactions").add(record);

//                                 db.collection("Attempts")
//                                   .doc(record.id)
//                                   .set({ toggle: true }, { merge: true })
//                                   .then(() => {
//                                     console.log("Toggled Done");
//                                   })
//                                   .catch((error) => {
//                                     console.error(
//                                       "Error creating toggling:",
//                                       error
//                                     );
//                                   });

//                                 db.collection("BCS")
//                                   .doc("tj2dBnlhMZLs1aXEVbYT")
//                                   .get()
//                                   .then((balanceDoc) => {
//                                     const currentBalance =
//                                       balanceDoc.data().balance;
//                                     const newBalance = currentBalance + 100;
//                                     db.collection("BCS")
//                                       .doc("tj2dBnlhMZLs1aXEVbYT")
//                                       .set(
//                                         { balance: newBalance },
//                                         { merge: true }
//                                       )
//                                       .then(() => {
//                                         console.log(
//                                           "Tax collection created with initial balance:"
//                                         );
//                                       })
//                                       .catch((error) => {
//                                         console.error(
//                                           "Error creating tax collection:",
//                                           error
//                                         );
//                                       });
//                                   })
//                                   .catch((error) => {
//                                     console.error(
//                                       "Error fetching purpose balance:",
//                                       error
//                                     );
//                                   });
//                                 // }

//                                 // Fetch the current balance of the selected purpose
//                                 db.collection("BCS")
//                                   .doc(record.bcsid)
//                                   .get()
//                                   .then((balanceDoc) => {
//                                     const currentBalance = balanceDoc.exists
//                                       ? balanceDoc.data().balance
//                                       : 0;
//                                     const newBalance =
//                                       currentBalance +
//                                       record.PayeeAmount -
//                                       (record.bcsid === "tj2dBnlhMZLs1aXEVbYT"
//                                         ? 0
//                                         : 100);

//                                     // Update the purpose balance
//                                     db.collection("BCS")
//                                       .doc(record.bcsid)
//                                       // .doc(PayeePurpose)
//                                       .set(
//                                         { balance: newBalance },
//                                         { merge: true }
//                                       )
//                                       .then(() => {
//                                         console.log("Purpose balance updated:");
//                                         //   const retrievedReferenceId = response.reference;
//                                         //      setReferenceId(retrievedReferenceId);
//                                         //   setTransactionData({
//                                         //     paymentId: null,
//                                         //     userData: userData,
//                                         //     transactionDate: new Date(),
//                                         //   });
//                                       })
//                                       .catch((error) => {
//                                         console.error(
//                                           "Error updating purpose balance:",
//                                           error
//                                         );
//                                       });
//                                   })
//                                   .catch((error) => {
//                                     console.error(
//                                       "Error fetching purpose balance:",
//                                       error
//                                     );
//                                   });

//                                 console.log(
//                                   "Attempted data saved successfully"
//                                 );
//                               } catch (error) {
//                                 console.error(
//                                   "Error saving payment data: ",
//                                   error
//                                 );
//                               }
//                             }}
//                             className="px-2 py-2 font-bold text-sm cursor-pointer"
//                           >
//                             <BiToggleLeft
//                               size={50}
//                               className="text-yellow-600"
//                             />
//                             {/* { !record.toggle ? 
//                         :
//                         <BiSolidToggleRight size={50} className="text-green-600"/>
//                         } */}
//                           </td>
//                         ) : (
//                           <td className="px-2 py-2 font-bold text-sm cursor-pointer">
//                             <BiSolidToggleRight
//                               size={50}
//                               className="text-green-600"
//                             />
//                           </td>
//                         )}
//                       </>
//                     ) : null}
//                   </tr>
//                 ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default FinanceA;
