import React, { useEffect, useState } from "react";
import { Table, Space, Modal, Button, Input } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import { useUser } from "../user-info/UserContext";

const StateRetirement = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQueryModalOpen, setIsQueryModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [queryInput, setQueryInput] = useState("");
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const {Search} = Input;
  const {userDepartment} = useUser()


  console.log(userDepartment.department)

  const handleSearch = (value) => {
    setQueryInput(value);
  };

  const showModal = (request) => {
    setSelectedRequest(request);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsQueryModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsQueryModalOpen(false);
  };

  const showQueryModal = () => {
    setIsQueryModalOpen(true);
  };

  const handleApprove = async (requestId) => {
    try {
      // Update the 'approved' field to true in Firestore
      const requestDocRef = doc(db, "retirements", requestId);
      await updateDoc(requestDocRef, { approved: true });

      // Send email to the user using EmailJS
      const serviceId = "your_emailjs_service_id";
      const templateId = "your_emailjs_template_id";
      const publicKey = "your_emailjs_public_key";

      const templateParams = {
        // name: user.firstName,
        // email: user.email,
        to_name: "Web Wizard",
      };

      emailjs
        .send(serviceId, templateId, templateParams, publicKey)
        .then((response) => {
          console.log("Email sent successfully!", response);
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });

      toast.success(`Request with ID ${requestId} has been approved.`);
    } catch (error) {
      console.error("Error approving request:", error);
    }
  };

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "retirements"));
        const requestData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })).filter((request) => request.stateid === userDepartment.department); // Filter requests based on stateid
        setRequests(requestData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching requests:", error);
        setLoading(false);
      }
    };
  
    fetchRequests();
  }, [userDepartment.department]);
  
  const columns = [
    {
      title: "Fellowship",
      dataIndex: "fellowship",
      width: 150,
    },
    {
      title: "Date",
      dataIndex: "date",
      width: 120,
    },
    {
      title: "Topic",
      dataIndex: "topic",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => showModal(record)} className="bg-blue-500">
            View
          </Button>
        </Space>
      ),
      width: 200,
    },
    {
        title: "Approval Status",
        dataIndex: "approved",
        render: (text, record) => (
          <Space size="middle">
            {record.approved ? (
              <span style={{ color: "green" }}>
                <CheckCircleOutlined />
              </span>
            ) : (
              <span style={{ color: "red" }}>
                <CloseCircleOutlined />
              </span>
            )}
          </Space>
        ),
        width: 150,
      },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = "your_emailjs_service_id";
    const templateId = "your_emailjs_template_id";
    const publicKey = "your_emailjs_public_key";

    const templateParams = {
      // name: user.firstName,
      // email: user.email,
      to_name: "Web Wizard",
      message: message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const filteredRequests = requests.filter((request) => {
    // Filter based on the search input (you can customize this logic)
    return (
      (request.fellowship &&
        request.fellowship.toLowerCase().includes(queryInput.toLowerCase())) ||
      (request.date &&
        request.date.toLowerCase().includes(queryInput.toLowerCase())) ||
      (request.topic &&
        request.topic.toLowerCase().includes(queryInput.toLowerCase()))
    );
  });


  return (
    <div className="mt-10 p-10 ml-11">
      <h1>Retirements </h1>
      <Search
        placeholder="Search requests..."
        // onSearch={handleSearch}
        onChange={(e) => handleSearch(e.target.value)}
        // value={searchText}
        style={{ width: "80%", maxWidth: "300px", marginBottom: "1rem" }}
        // enterButton
      />
      <Table
        loading={loading}
        columns={columns}
        dataSource={filteredRequests}
        pagination={{
          pageSize: 5,
        }}
        scroll={{ x: "max-content" }}
      />

      <Modal
        title="Request Details"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="70%"
      >
        {selectedRequest && (
          <div className="p-4 w-[100%]">
            <div className="mb-4">
              <p className="text-lg font-bold">Date: {selectedRequest.date}</p>
              <p className="text-lg font-bold">
                Fellowship: {selectedRequest.fellowship}
              </p>
            </div>
            <div className="mb-4">
              <p className="text-xl font-bold">
                Title: {selectedRequest.topic}
              </p>
            </div>
            <div className="mb-4">
              <p>The Auditor BCS Establishments / Departments</p>
              <p className="mb-2">
                I hope this letter finds you well. We would like to bring to
                your attention the following request details:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>
                  <strong>Retirement:</strong>
                  {selectedRequest.attachment && (
                    <a
                      href={selectedRequest.attachment.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline ml-1"
                    >
                      {selectedRequest.attachment.name}
                    </a>
                  )}
                </li>
                <li>
                  <strong>Head :</strong> {selectedRequest.Head}
                </li>
                <li>
                  <strong>Finance Officer : </strong>{" "}
                  {selectedRequest.financeOfficer}
                </li>
                <li>
                  <strong>Treasurer : </strong> {selectedRequest.treasurer}
                </li>
                <li>
                  <strong>COH:</strong>
                  {selectedRequest.coh && (
                    <a
                      href={selectedRequest.coh.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline ml-1"
                    >
                      {selectedRequest.coh.name}
                    </a>
                  )}
                </li>
                {/* Add more details as needed */}
              </ul>
              <p className="mb-4">
                We kindly request your approval for this matter. Your prompt
                attention to this request is highly appreciated.
              </p>
              <p className="mb-4">Thank you for your cooperation.</p>
              <p>Sincerely,</p>
              <p className="font-bold">{selectedRequest.fellowship}</p>
            </div>
            <div className="flex justify-end">
              <Button
                type="primary"
                onClick={() => handleApprove(selectedRequest.id)}
                className="bg-green-500 mr-2"
              >
                Accept
              </Button>
              <Button
                type="primary"
                onClick={showQueryModal}
                className="bg-gray-600"
              >
                Query
              </Button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        title="Query Input"
        visible={isQueryModalOpen}
        onOk={handleSubmit}
        onCancel={handleCancel}
      >
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter your query here..."
          className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
        />
      </Modal>
    </div>
  );
};

export default StateRetirement;

 