import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { toast } from "react-toastify";
// import { useAuth } from '../Context/Context';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useUser } from "../user-info/UserContext";

const RequisitionForm = () => {
  const { userDepartment } = useUser();
  const [loading, setLoading] = useState(false);

  // const { user } = useAuth()
  const [formData, setFormData] = useState({
    date: new Date(),
    fellowship: "",
    amount: "",
    topic: "",
    body: "",
    accountNum: "",
    accountName: "",
    bankName: "",
    lastRequisitionDate: "",
    auditClearance: null,
    authorizedBy: "",
    fellowshipHead: "",
    financeOfficer: "",
    treasurer: "",
    fellowshipHeadnum: "",
    financeOfficernum: "",
    treasurernum: "",
    attachments: [],
    attachmentInfo: null,
  });

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleFileChange = (files) => {
    const fileInformationArray = [];

    for (let i = 0; i < files.length; i++) {
      const fileInformation = {
        name: files[i].name,
        type: files[i].type,
        size: files[i].size,
      };
      fileInformationArray.push(fileInformation);
    }

    setFormData({
      ...formData,
      attachments: files,
      attachmentInfo: fileInformationArray,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const currentDate = new Date();
      const formattedDate = `${currentDate.getMonth() + 1
        }/${currentDate.getDate()}/${currentDate.getFullYear()}`;

      const storage = getStorage();
      const attachmentsURLs = [];

      // Upload each attachment to Firebase Storage
      for (let i = 0; i < formData.attachments.length; i++) {
        const attachment = formData.attachments[i];
        const storageRef = ref(storage, `attachments/${attachment.name}`);
        await uploadBytes(storageRef, attachment);
        const downloadURL = await getDownloadURL(storageRef);
        attachmentsURLs.push({
          name: attachment.name,
          type: attachment.type,
          size: attachment.size,
          url: downloadURL,
        });
      }

      // Save the form data in Firestore
      // const docRef = await addDoc(collection(db, "request"), {
       await addDoc(collection(db, "request"), {
        ...formData,
        // date: formattedDate, // Set the "date" field to the current date
        attachments: attachmentsURLs, // Store attachment URLs in the database
        approved: false,
      });

      // console.log(formData)

      toast.success(
        "Your Requisition has been sent. The supervisor will look into it and get back to you soon 🤝🤝🤝"
      );

      // Reset the form after submission
      setFormData({
        from: userDepartment.email,
        date: new Date(), // Reset the date field to an empty string
        amount: "",
        fellowship: "",
        topic: "",
        body: "",
        attachments: [],
        attachmentInfo: null,
        accountNum: "",
        accountName: "",
        bankName: "",
        lastRequisitionDate: "",
        auditClearance: null,
        authorizedBy: "",
        fellowshipHead: "",
        financeOfficer: "",
        treasurer: "",
        fellowshipHeadnum: "",
        financeOfficernum: "",
        treasurernum: "",
      });

      setLoading(false);
    } catch (error) {
      toast.error(`Error adding document: ${error}`);
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-8 p-4 bg-white rounded shadow-md ">
      <h1 className="text-2xl font-bold mb-4 text-gray-800">Requisition</h1>
      <form onSubmit={handleFormSubmit}>
        {/* Date and Organs Row */}
        <div className="flex mb-4">
          <div className="w-[50%]">
            <label htmlFor="fellowship" className="text-sm text-gray-600">
              Organ
            </label>
            <input
              type="text"
              id="fellowship"
              placeholder="Name of Fellowship / Department / State / Group"
              className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
              value={formData.fellowship}
              onChange={(e) => handleChange("fellowship", e.target.value)}
              required
            />
          </div>
          <div>
            <input type="number" id="amount" placeholder="Amount" className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300 mt-7 ml-9" 
                   value={formData.amount}
                   onChange={(e) => handleChange("amount", e.target.value)}
                   required
            />
          </div>
        </div>


        {/* Title and Body Row */}
        <div className="mb-4">
          <label htmlFor="topic" className="text-sm text-gray-600">
            Title
          </label>
          <input
            placeholder="Requisition For ........"
            type="text"
            id="topic"
            className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
            value={formData.topic}
            onChange={(e) => handleChange("topic", e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="body" className="text-sm text-gray-600">
            Body
          </label>
          <ReactQuill
            id="body"
            value={formData.body}
            onChange={(value) => handleChange("body", value)}
            theme="snow"
            className="border rounded focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>

        {/* Attachment Row */}
        <div className="mb-4">
          <label htmlFor="attachment" className="text-sm text-gray-600">
            Attachment
          </label>
          <input
            type="file"
            id="attachment"
            accept=".jpeg, .jpg, .pdf, .doc, .docx, .xls, .xlsx"
            onChange={(e) => handleFileChange(e.target.files)}
            className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
            multiple // Enable multiple file selection
          />
        </div>

        {/* Account Number, Account Name, and Bank Row */}
        <div className="flex mb-4">
          <div className="w-1/3 mr-2">
            <label htmlFor="accountNum" className="text-sm text-gray-600">
              Account Number
            </label>
            <input
              type="text"
              id="accountNum"
              className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
              value={formData.accountNum}
              onChange={(e) => handleChange("accountNum", e.target.value)}
              required
            />
          </div>
          <div className="w-1/3 mx-2">
            <label htmlFor="accountName" className="text-sm text-gray-600">
              Account Name
            </label>
            <input
              type="text"
              id="accountName"
              className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
              value={formData.accountName}
              onChange={(e) => handleChange("accountName", e.target.value)}
              required
            />
          </div>
          <div className="w-1/3 mx-2">
            <label htmlFor="bankName" className="text-sm text-gray-600">
              {" "}
              Bank Name
            </label>
            <input
              type="text"
              id="bankName"
              className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
              value={formData.bankName}
              onChange={(e) => handleChange("bankName", e.target.value)}
              required
            />
          </div>
        </div>
        <div className=" ml-2">
          <label
            htmlFor="lastRequisitionDate"
            className="text-sm text-gray-600"
          >
            Date of Last Requisition (optional)
          </label>
          <input
            type="date"
            id="lastRequisitionDate"
            className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
            value={formData.lastRequisitionDate}
            onChange={(e) =>
              handleChange("lastRequisitionDate", e.target.value)
            }
          />
        </div>
        {/* Authorized by Row */}
        <div className="mb-4">
          <h2 className="text-lg font-bold mb-2 text-gray-800">
            Authorized by
          </h2>
          <div className="flex mb-4">
            <div className="w-1/3 mr-2">
              <label htmlFor="fellowshipHead" className="text-sm text-gray-600">
                Head / Number
              </label>
              <input
                type="text"
                id="fellowshipHead"
                className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                value={formData.fellowshipHead}
                onChange={(e) => handleChange("fellowshipHead", e.target.value)}
                required
              />
            </div>
            <div className="w-1/3 mx-2">
              <label htmlFor="financeOfficer" className="text-sm text-gray-600">
                Finance Officer / Number
              </label>
              <input
                type="text"
                id="financeOfficer"
                className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                value={formData.financeOfficer}
                onChange={(e) => handleChange("financeOfficer", e.target.value)}
                required
              />
            </div>
            <div className="w-1/3 ml-2">
              <label htmlFor="treasurer" className="text-sm text-gray-600">
                Treasurer / Number
              </label>
              <input
                type="text"
                id="treasurer"
                className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                value={formData.treasurer}
                onChange={(e) => handleChange("treasurer", e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-1/3 mr-2">
              <label htmlFor="fellowshipHeadnum" className="text-sm text-gray-600">
                Head Number
              </label>
              <input
                type="text"
                id="fellowshipHeadnum"
                className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                value={formData.fellowshipHeadnum}
                onChange={(e) => handleChange("fellowshipHeadnum", e.target.value)}
                required
              />
            </div>
            <div className="w-1/3 mx-2">
              <label htmlFor="financeOfficernum" className="text-sm text-gray-600">
                Finance Officer Number
              </label>
              <input
                type="text"
                id="financeOfficernum"
                className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                value={formData.financeOfficernum}
                onChange={(e) => handleChange("financeOfficernum", e.target.value)}
                required
              />
            </div>
            <div className="w-1/3 ml-2">
              <label htmlFor="treasurernum" className="text-sm text-gray-600">
                Treasurer Number
              </label>
              <input
                type="text"
                id="treasurernum"
                className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                value={formData.treasurernum}
                onChange={(e) => handleChange("treasurernum", e.target.value)}
                required
              />
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="mb-4">
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300 ml-72 mt-2"
          >
            {loading ? "Submiting......." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default RequisitionForm;