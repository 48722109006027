import React from 'react'
import ProductTable from '../components/Table/ProductTable'
import DepartmentTable from '../components/Table/DepartMentTable'

const Department = () => {
  return (
    <h1 className="text-3xl text-red-700 font-bold ">
      <DepartmentTable/>
    </h1>
  )
}

export default Department