import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { toast } from 'react-toastify';
import { Firestore, addDoc, collection, serverTimestamp, getDocs } from 'firebase/firestore';

const CreateStaff = () => {
    const [lastName, setLastName] = useState('');
    const [otherNames, setOtherNames] = useState('');
    const [gender, setGender] = useState('');
    const [email, setEmail] = useState('');
    const [dateOfEmployment, setDateOfEmployment] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [department, setDepartment] = useState('');
    const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState('');
    const [contactAddress, setContactAddress] = useState('');
    const [bank, setBank] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [payable, setPayable] = useState('');

    const [loading, setLoading] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [loadingDepartments, setLoadingDepartments] = useState(true);

    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "BCS"));
                const departmentNames = querySnapshot.docs.map(doc => doc.data().department_name);
                setDepartments(departmentNames);
                setLoadingDepartments(false);
            } catch (error) {
                console.error('Error fetching departments:', error);
                setLoadingDepartments(false);
            }
        };

        fetchDepartments();
    }, []);

    async function uploadStaffDetails() {
        if (lastName.length < 1 || otherNames.length < 1 || gender.length < 1 || email.length < 1 ||
            dateOfEmployment.length < 1 || jobTitle.length < 1 || department.length < 1 ||
            primaryPhoneNumber.length < 1 || contactAddress.length < 1 || bank.length < 1 ||
            accountNumber.length < 1 || payable.length < 1) {
            toast.error("Please fill in all fields");
            return;
        }

        setLoading(true);

        try {
            const docRef = await addDoc(collection(db, "Staff"), {
                last_name: lastName,
                other_names: otherNames,
                gender: gender,
                email: email,
                date_of_employment: dateOfEmployment,
                job_title: jobTitle,
                department: department,
                primary_phone_number: primaryPhoneNumber,
                contact_address: contactAddress,
                bank: bank,
                account_number: accountNumber,
                payable: payable,
                created_by: auth.currentUser.email,
                created_at: serverTimestamp()
            });
            setLoading(false);
            toast.success("Staff details saved!");
        } catch (e) {
            setLoading(false);
            toast.error('An error occurred: ' + e.message);
        }
    }

    return (
        <div>
        <div className="min-h-screen p-6 bg-gray-100 flex items-center justify-center">
            <div className="container max-w-screen-lg mx-auto">
                <div>
                    <h2 className="font-semibold text-xl text-gray-600">Add Staff</h2>
                    <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                        <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                            <div className="lg:col-span-2">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-2 lg:grid-cols-3">

                                        <div className="">
                                            <label htmlFor="lastName">Last Name</label>
                                            <input
                                                onChange={(e) => setLastName(e.target.value)}
                                                type="text"
                                                name="lastName"
                                                id="lastName"
                                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                                value={lastName}
                                            />
                                        </div>

                                        <div className="">
                                            <label htmlFor="otherNames">Other Names</label>
                                            <input
                                                onChange={(e) => setOtherNames(e.target.value)}
                                                type="text"
                                                name="otherNames"
                                                id="otherNames"
                                                className="h-10 border mt-1 rounded px-4  bg-gray-50"
                                                value={otherNames}
                                            />
                                        </div>

                                       
                                        <div className="">
                                            <label>Gender</label>
                                            <div className="flex items-center mt-1">
                                                <label className="mr-4">
                                                    <input
                                                        type="radio"
                                                        name="gender"
                                                        value="brother"
                                                        checked={gender === 'brother'}
                                                        onChange={() => setGender('brother')}
                                                    />
                                                    Brother
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="gender"
                                                        value="sister"
                                                        checked={gender === 'sister'}
                                                        onChange={() => setGender('sister')}
                                                    />
                                                    Sister
                                                </label>
                                            </div>
                                        </div>
                                        <div className="    ">
                                            <label htmlFor="email">Email Address</label>
                                            <input
                                                onChange={(e) => setEmail(e.target.value)}
                                                type="email"
                                                name="email"
                                                id="email"
                                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                                value={email}
                                            />
                                        </div>

                                        <div className="">
                                            <label htmlFor="dateOfEmployment">Date of Employment</label>
                                            <input
                                                onChange={(e) => setDateOfEmployment(e.target.value)}
                                                type="date"
                                                name="dateOfEmployment"
                                                id="dateOfEmployment"
                                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                                value={dateOfEmployment}
                                            />
                                        </div>

                                        <div className="">
                                            <label htmlFor="jobTitle">Job Title</label>
                                            <input
                                                onChange={(e) => setJobTitle(e.target.value)}
                                                type="text"
                                                name="jobTitle"
                                                id="jobTitle"
                                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                                value={jobTitle}
                                            />
                                        </div>

                                        <div className="">
                    <label htmlFor="department">Department</label>
                    {loadingDepartments ? (
                        <div>Loading departments...</div>
                    ) : (
                        <select
                            onChange={(e) => setDepartment(e.target.value)}
                            value={department}
                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        >
                            <option value="">Select  department</option>
                            {departments.map((dept, index) => (
                                <option key={index} value={dept}>
                                    {dept}
                                </option>
                            ))}
                        </select>
                    )}
                </div>


                                        <div className="">
                                            <label htmlFor="primaryPhoneNumber">Primary Phone Number</label>
                                            <input
                                                onChange={(e) => setPrimaryPhoneNumber(e.target.value)}
                                                type="tel"
                                                name="primaryPhoneNumber"
                                                id="primaryPhoneNumber"
                                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                                value={primaryPhoneNumber}
                                            />
                                        </div>

                                        <div className="">
                                            <label htmlFor="contactAddress">Contact Address</label>
                                            <input
                                                onChange={(e) => setContactAddress(e.target.value)}
                                                type="text"
                                                name="contactAddress"
                                                id="contactAddress"
                                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                                value={contactAddress}
                                            />
                                        </div>

                                        <div className="">
                                            <label htmlFor="bank">Bank</label>
                                            <input
                                                onChange={(e) => setBank(e.target.value)}
                                                type="text"
                                                name="bank"
                                                id="bank"
                                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                                value={bank}
                                            />
                                        </div>

                                        <div className="">
                                            <label htmlFor="accountNumber">Account Number</label>
                                            <input
                                                onChange={(e) => setAccountNumber(e.target.value)}
                                                type="text"
                                                name="accountNumber"
                                                id="accountNumber"
                                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                                value={accountNumber}
                                            />
                                        </div>

                                        <div className="">
                                            <label>Payable</label>
                                            <div className="flex items-center mt-1">
                                                <label className="mr-4">
                                                    <input
                                                        type="radio"
                                                        name="payable"
                                                        value="yes"
                                                        checked={payable === 'yes'}
                                                        onChange={() => setPayable('yes')}
                                                    />
                                                    Yes
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="payable"
                                                        value="no"
                                                        checked={payable === 'no'}
                                                        onChange={() => setPayable('no')}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        <div className="md:col-span-2 text-right">
                                            <div className="inline-flex items-end" onClick={() => uploadStaffDetails()}>
                                                <button
                                                    disabled={loading}
                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    {loading ? 'Saving...' : 'Submit'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateStaff;
