import React, { useEffect, useState } from "react";
import firebase from "../firebaseconfig";
import { Button, Table } from "antd";

const Treasury = () => {
  const [records, setRecords] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    // Create a query for the "Transactions" collection
    const db = firebase.firestore();
    const transactionRef = db
      .collection("Transactions")
      .where("type", "==", "Withdrawal")
      .orderBy("createdAt", "desc");
  
    transactionRef
      .get()
      .then((querySnapshot) => {
        const data = [];
        let total = 0; // Initialize total amount
        querySnapshot.forEach((doc) => {
          const transactionData = doc.data();
          data.push({ id: doc.id, ...transactionData, createdAt: formatDate(transactionData.createdAt) });
          total += parseFloat(transactionData.PayeeAmount); // Add amount to total
        });
        setTotalAmount(total); // Set the total amount
        setRecords(data); // Set the data to the records state
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString.seconds * 1000); // Convert Firestore timestamp to JavaScript Date object
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const columns = [
    {
      title: "Amount",
      dataIndex: "PayeeAmount",
      key: "PayeeAmount",
      render: (text, record) => (
        <span
          className={`font-bold text-lg ${
            record.type === "Withdrawal" ? "text-red-500" : "text-green-500"
          }`}
        >
          ₦{text?.toLocaleString("en-US") || "0"}
        </span>
      ),
    },
    {
      title: "Purpose",
      dataIndex: "PaymentDescription",
      key: "PaymentDescription",
    },
    {
      title: "Withdrawn by",
      dataIndex: "payeeName",
      key: "payeeName",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span className="font-bold text-lg">{text}</span>,
    },
    // Add more columns as needed
  ];

  const printTable = () => {
    const printWindow = window.open('', '_blank'); // Open a new window
    printWindow.document.write('<html><head><title>Print</title></head><body>'); // Write HTML content
    printWindow.document.write('<h2>Total Amount Withdrawn: ₦' + totalAmount + '</h2>'); // Write total amount
    printWindow.document.write('<table border="1">'); // Start table
    printWindow.document.write('<tr><th>Amount</th><th>Purpose</th><th>Withdrawn by</th><th>Date</th></tr>'); // Table headers
    records.forEach((record) => {
      printWindow.document.write('<tr>'); // Start table row
      printWindow.document.write('<td>' + record.PayeeAmount + '</td>'); // Amount
      printWindow.document.write('<td>' + record.PaymentDescription + '</td>'); // Purpose
      printWindow.document.write('<td>' + record.payeeName + '</td>'); // Withdrawn by
      printWindow.document.write('<td>' + record.createdAt + '</td>'); // Date
      printWindow.document.write('</tr>'); // End table row
    });
    printWindow.document.write('</table>'); // End table
    printWindow.document.write('</body></html>'); // End HTML content
    printWindow.document.close(); // Close document
    printWindow.print(); // Print
  };

  return (
    <div>
      <h2 className="text-center mb-3 text-xl font-bold">Total Amount Withdrawn: ₦{totalAmount}</h2>
      {/* <Button type="primary" onClick={printTable} className="mb-3">Print</Button> */}
      <Table dataSource={records} columns={columns} />
    </div>
  );
};

export default Treasury;
