import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { db } from "../firebase";
import firebase from '../firebaseconfig';
import { collection, doc, addDoc, getDocs, getDoc, onSnapshot } from "firebase/firestore";

const DepartmentBalance = ({ departmentsData }) => {
  const { department_id } = useParams();
  const [departmentData, setDepartmentData] = useState({});
  const [recordType, setRecordType] = useState("Income");
  const [recordFrom, setRecordFrom] = useState("");
  const [recordPrice, setRecordPrice] = useState("");
  const [recordDate, setRecordDate] = useState("");
  const [records, setRecords] = useState([]);
  const [previousBalance, setPreviousBalance] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = () => {
    const filteredRecords = records.filter((record) => {
      return (
        record.price.includes(searchTerm) ||
        record.date.includes(searchTerm) ||
        record.from.includes(searchTerm) ||
        record.type.includes(searchTerm)
      );
    });
    setSearchResults(filteredRecords);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setSearchResults([]);
  };

  useEffect(() => {
    // Fetch department data from Firestore
    const fetchDepartment = async () => {
      const departmentRef = doc(db, "BCS", department_id);
      const departmentSnapshot = await getDoc(departmentRef);
      if (departmentSnapshot.exists()) {
        setDepartmentData(departmentSnapshot.data());
      }
    };

    // Fetch records from Firestore
    const fetchRecords = async () => {
      const recordsCollection = collection(db, "BCS", department_id, "Records");
      const recordsSnapshot = await getDocs(recordsCollection);
      const recordsData = recordsSnapshot.docs.map((doc) => doc.data());
      setRecords(recordsData);
    };

    fetchDepartment();
    fetchRecords();
  }, [department_id]);
      console.log(departmentData)

  useEffect(() => {
    const initialBalance = records.reduce((acc, record) => {
      if (record.type === "Income") {
        return acc + parseFloat(record.price);
      } else if (record.type === "Expenditure") {
        return acc - parseFloat(record.price);
      }
      return acc;
    }, 0);

    setCurrentBalance(initialBalance);
  }, [records]);

  const loadRecordsFromFirebase = async (departmentId) => {
    const recordsCollection = collection(
      db,
      "BCS",
      departmentId,
      "Records"
    );
    const querySnapshot = await getDocs(recordsCollection);
    const recordsData = querySnapshot.docs.map((doc) => doc.data());
    setRecords(recordsData);

    // Filter out the "Balance" type records
    const balanceRecords = recordsData.filter(
      (record) => record.type === "Balance"
    );

    if (balanceRecords.length > 0) {
      // Sort the balance records by date in descending order
      balanceRecords.sort((a, b) => new Date(b.date) - new Date(a.date));

      // Get the most recent "Previous Balance" record
      const mostRecentBalanceRecord = balanceRecords[0];

      // Extract the previous balance from the record
      const previousBalance = parseFloat(mostRecentBalanceRecord.price);
      setPreviousBalance(previousBalance);
    }
  };

  const handleRecordSubmit = async () => {
    if (recordFrom && recordPrice && recordDate) {
      const newRecord = {
        from: recordFrom,
        price: recordPrice,
        type: recordType,
        date: recordDate,
      };

      let newBalance = currentBalance;

      if (recordType === "Income") {
        newBalance += parseFloat(recordPrice);
      } else if (recordType === "Expenditure") {
        newBalance -= parseFloat(recordPrice);
      }

      // Create a new record for the previous balance
      const previousBalanceRecord = {
        from: "Previous Balance",
        price: previousBalance.toString(),
        type: "Balance",
        date: recordDate,
      };

      // Add the previous balance record to the Firestore database
      const recordsCollection = collection(
        db,
        "BCS",
        department_id,
        "Records"
      );
      await addDoc(recordsCollection, previousBalanceRecord);

      // Create a new record for the current balance
      const balanceRecord = {
        from: "Balance Update",
        price: newBalance.toString(),
        type: "Balance",
        date: recordDate,
      };

      // Add the balance record to the Firestore database
      await addDoc(recordsCollection, balanceRecord);

      setPreviousBalance(currentBalance);
      setCurrentBalance(newBalance);

      // Add the new record to the Firestore database
      await addDoc(recordsCollection, newRecord);

      loadRecordsFromFirebase(department_id);

      setRecordFrom("");
      setRecordPrice("");
      setRecordDate("");
    }
  };

  const handlePrint = () => {
    const printWindow = window.open("", "", "width=800,height=600");
  
    printWindow.document.write("<html><head><title>BCS HR</title>");
    printWindow.document.write('<style>');
    printWindow.document.write('body { font-family: Arial, sans-serif; padding: 20px; }');
    printWindow.document.write('h1 { font-size: 24px; margin-bottom: 20px; }');
    printWindow.document.write('table { width: 100%; border-collapse: collapse; }');
    printWindow.document.write('th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }');
    printWindow.document.write('th { background-color: #f2f2f2; }');
    printWindow.document.write('.text-red { color: red; }');
    printWindow.document.write('.text-green { color: green; }');
    printWindow.document.write('</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write("<h1>" + departmentData.department_name + " Records</h1>");
    printWindow.document.write("<p>Profitability: " + departmentData.profitability + "</p>");
    printWindow.document.write("<p>Previous Balance: ₦" + previousBalance + "</p>");
    printWindow.document.write("<p>Current Balance: ₦" + currentBalance + "</p");
  
    if (searchResults.length > 0) {
      // If there are search results, calculate total income and total expenditure from the search results
      const totalIncome = searchResults
        .filter((record) => record.type === "Income")
        .reduce((total, record) => total + parseFloat(record.price), 0);
  
      const totalExpenditure = searchResults
        .filter((record) => record.type === "Expenditure")
        .reduce((total, record) => total + parseFloat(record.price), 0);
      
      printWindow.document.write("<p>Total Income: ₦" + totalIncome + "</p>");
      printWindow.document.write("<p>Total Expenditure: ₦" + totalExpenditure + "</p>");
  
      // Calculate and display the grand total based on search results
      const grandTotal = currentBalance + totalIncome - totalExpenditure;
      printWindow.document.write("<p>Grand Total: ₦" + grandTotal + "</p>");
    } else {
      // If there are no search results, calculate totals based on all records
      const totalIncome = filteredRecords
        .filter((record) => record.type === "Income")
        .reduce((total, record) => total + parseFloat(record.price), 0);
  
      const totalExpenditure = filteredRecords
        .filter((record) => record.type === "Expenditure")
        .reduce((total, record) => total + parseFloat(record.price), 0);
      
      printWindow.document.write("<p>Total Income: ₦" + totalIncome + "</p>");
      printWindow.document.write("<p>Total Expenditure: ₦" + totalExpenditure + "</p>");
  
      // Calculate and display the grand total based on all records
      const grandTotal = currentBalance + totalIncome - totalExpenditure;
      printWindow.document.write("<p>Grand Total: ₦" + grandTotal + "</p>");
    }
  
    // Generate the table HTML
    printWindow.document.write('<table>');
    printWindow.document.write('<thead>');
    printWindow.document.write('<tr>');
    printWindow.document.write('<th>Type</th>');
    printWindow.document.write('<th>For</th>');
    printWindow.document.write('<th>Price</th>');
    printWindow.document.write('<th>Date</th>');
    printWindow.document.write('</tr>');
    printWindow.document.write('</thead>');
    printWindow.document.write('<tbody>');
  
    // Generate table rows from searchResults or filteredRecords based on whether there are search results
    (searchResults.length > 0 ? searchResults : filteredRecords).forEach((record) => {
      const typeClass = record.type === "Expenditure" ? "text-red" : "text-green";
      printWindow.document.write('<tr>');
      printWindow.document.write('<td class="' + typeClass + '">' + record.type + '</td>');
      printWindow.document.write('<td>' + record.from + '</td>');
      printWindow.document.write('<td>₦' + record.price + '</td>');
      printWindow.document.write('<td>' + record.date + '</td>');
      printWindow.document.write('</tr>');
    });
  
    // Close the table and body
    printWindow.document.write('</tbody>');
    printWindow.document.write('</table>');
    printWindow.document.write('</body></html>');
  
    // Trigger the print dialog
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  

  // Filter out the "Balance" records before rendering the table
  const filteredRecords = records.filter((record) => record.type !== "Balance");
  console.log(records)
  return (
    <div className="p-4">
      {departmentData ? (
        <div>
          <h1 className="text-2xl font-bold mb-4">
            {departmentData.department_name}
          </h1>
          <Link  to={`/departmentbalance/${department_id}`}  className="text-lg ml-[80%] font-semibold mb-2">
            <button className="bg-blue-500 text-white text-2xl rounded-2xl w-40 h-20">
            Finance
            </button>
          </Link>

          <p className="text-lg font-semibold mb-2">
            Previous Balance: ₦{previousBalance}
          </p>

          <p className="text-lg font-semibold mb-2">
            Current Balance: ₦{currentBalance}
          </p>

          <div className="mt-4">
            <h2 className="text-xl font-semibold mb-2">
              Record Income/Expenditure
            </h2>
            <div className="flex space-x-4">
              <select
                onChange={(e) => setRecordType(e.target.value)}
                value={recordType}
                className="px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
              >
                <option value="Income">Income</option>
                <option value="Expenditure">Expenditure</option>
              </select>
              <input
                type="text"
                placeholder="For"
                value={recordFrom}
                onChange={(e) => setRecordFrom(e.target.value)}
                className="px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
              />
              <input
                type="number"
                placeholder="Price"
                value={recordPrice}
                onChange={(e) => setRecordPrice(e.target.value)}
                className="px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
              />
              <input
                type="date"
                placeholder="Date"
                value={recordDate}
                onChange={(e) => setRecordDate(e.target.value)}
                className="px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
              />
              <button
                onClick={handleRecordSubmit}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Save
              </button>
              <button
                onClick={handlePrint}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Print
              </button>
            </div>
          </div>
          <div className="mt-4">
            <h2 className="text-xl font-semibold mb-2">Search Records</h2>
            <div className="flex space-x-4">
              <input
                type="text"
                placeholder="Search by Price, Date, For, or Type"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
              />
              <button
                onClick={handleSearch}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Search
              </button>
              <button
                onClick={handleClearSearch}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Clear
              </button>
            </div>
          </div>

          <h2 className="text-xl font-semibold mt-8 mb-2">Records</h2>
          <table className="w-full text-sm text-left dark:text-gray-400">
            <thead className="uppercase bg-gray-50 dark:bg-gray-700">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  For
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {searchResults.length > 0
                ? searchResults.map((record, index) => (
                    <tr key={index}>
                      <td
                        className={`px-4 py-2 font-bold text-lg ${
                          record.type === "Expenditure"
                            ? "text-red-500"
                            : "text-green-500"
                        }`}
                      >
                        {record.type}
                      </td>
                      <td className="px-4 py-2 font-bold text-lg">
                        {record.from}
                      </td>
                      <td className="px-4 py-2 font-bold text-lg">
                        ₦{record.price}
                      </td>
                      <td className="px-4 py-2 font-bold text-lg">
                        {record.date}
                      </td>
                    </tr>
                  ))
                : filteredRecords.map((record, index) => (
                    <tr key={index}>
                      <td
                        className={`px-4 py-2 font-bold text-lg ${
                          record.type === "Expenditure"
                            ? "text-red-500"
                            : "text-green-500"
                        }`}
                      >
                        {record.type}
                      </td>
                      <td className="px-4 py-2 font-bold text-lg">
                        {record.from}
                      </td>
                      <td className="px-4 py-2 font-bold text-lg">
                        ₦{record.price}
                      </td>
                      <td className="px-4 py-2 font-bold text-lg">
                        {record.date}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-lg font-semibold">Department not found</p>
      )}
    </div>
  );
};

export default DepartmentBalance;
