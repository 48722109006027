import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import {LiaMoneyBillSolid} from 'react-icons/lia'
import {HiOutlineReply} from 'react-icons/hi'
import { Link } from 'react-router-dom';

const TaxBalance = () => {
  const [taxBalance, setTaxBalance] = useState(null);

  useEffect(() => {
    // Access the Firestore collection for tax balance
    firebase
      .firestore()
      .collection('Tax') // Replace with the actual collection name for tax
      .doc('taxDocument') // Replace with the document ID for tax
      .get()
      .then((doc) => {
        if (doc.exists) {
          const balance = doc.data().balance;
          setTaxBalance(balance);
        } else {
          // Tax document doesn't exist, set a default balance
          setTaxBalance(0);
        }
      })
      .catch((error) => {
        console.error('Error fetching tax balance:', error);
      });
  }, []);

  const currencyFormatter = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 2,
  });

  return (
    <div className='bg-white shadow-lg  w-96 h-44 border-b-2 text-center border-b-pink-500 p-2'>
        <Link to={ "/finance"}>
        <HiOutlineReply size={30} color='red' className='m-1 border-b-2 border-red-500'/>
        </Link>
        <LiaMoneyBillSolid color='red' size={50} className='border-2 border-red-500 rounded-full p-1'/> 
      <h1 className='font-bold mt- text-3xl'>Tax Balance</h1>
      <p className='font-bold text-2xl'>{currencyFormatter.format(taxBalance)} Naira</p>
    </div>
  );
};

export default TaxBalance;
