import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { auth, db, storage } from '../firebase';
import { toast } from 'react-toastify'
import { Firestore, addDoc, collection, serverTimestamp, getDocs } from 'firebase/firestore';

const AddToPayroll = () => {
    const [selectedName, setSelectedName] = useState('');
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [salaryAmount, setSalaryAmount] = useState('');

    const [loading, setLoading] = useState(false);
    const [names, setNames] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const namesQuerySnapshot = await getDocs(collection(db, "Staff"));
                const namesList = namesQuerySnapshot.docs.map(doc => doc.data().last_name + ', ' + doc.data().other_names);
                setNames(namesList);

                const departmentsQuerySnapshot = await getDocs(collection(db, "Departments"));
                const departmentNames = departmentsQuerySnapshot.docs.map(doc => doc.data().department_name);
                setDepartments(departmentNames);

                setLoadingData(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoadingData(false);
            }
        };

        fetchData();
    }, []);

    async function uploadStaffDetails() {
        if (!selectedName || !selectedDepartment || !salaryAmount) {
            toast.error("Please fill in all fields");
            return;
        }

        setLoading(true);

        try {
            const docRef = await addDoc(collection(db, "payroll"), {
                name: selectedName,
                department: selectedDepartment,
                salary_amount: salaryAmount,
                created_by: auth.currentUser.email,
                created_at: serverTimestamp()
            });

            setLoading(false);
            toast.success("Staff details saved!");
        } catch (error) {
            console.error('An error occurred:', error);
            setLoading(false);
            toast.error('An error occurred!');
        }
    }

    return (
        <div className="min-h-screen p-6 bg-gray-100 flex items-center justify-center">
            <div className="container max-w-screen-lg mx-auto">
                <div>
                    <h2 className="font-semibold text-xl text-gray-600">Add Staff</h2>
                    <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                        <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                            <div className="lg:col-span-2">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                                    <div className="">
                                        <label htmlFor="selectedName">Select Name</label>
                                        <select
                                            onChange={(e) => setSelectedName(e.target.value)}
                                            value={selectedName}
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                        >
                                            <option value="">Select a name</option>
                                            {names.map((name, index) => (
                                                <option key={index} value={name}>
                                                    {name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="">
                                        <label htmlFor="selectedDepartment">Select Department</label>
                                        {loadingData ? (
                                            <div>Loading departments...</div>
                                        ) : (
                                            <select
                                                onChange={(e) => setSelectedDepartment(e.target.value)}
                                                value={selectedDepartment}
                                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            >
                                                <option value="">Select a department</option>
                                                {departments.map((dept, index) => (
                                                    <option key={index} value={dept}>
                                                        {dept}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>

                                    <div className="">
                                        <label htmlFor="salaryAmount">Salary Amount</label>
                                        <input
                                            onChange={(e) => setSalaryAmount(e.target.value)}
                                            type="number"
                                            name="salaryAmount"
                                            id="salaryAmount"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={salaryAmount}
                                        />
                                    </div>

                                    <div className="md:col-span-2 text-right">
                                        <div className="inline-flex items-end" onClick={() => uploadStaffDetails()}>
                                            <button
                                                disabled={loading}
                                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                            >
                                                {loading ? 'Saving...' : 'Add to Payroll'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddToPayroll;


